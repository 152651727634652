import Info from '@mui/icons-material/InfoOutlined';
import Check from '@mui/icons-material/CheckCircle';
import styled from 'styled-components';

import Typography from '@mui/material/Typography';

export const PunchListItem = ({
  complete,
  children,
  style
}) => {
  return (
    <Wrapper style={style}>
      {complete ? <Check color='success' /> : <Info />}
      <Typography variant='body1' color='textPrimary'>{children}</Typography>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  svg {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
`
