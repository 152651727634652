import { useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import { useSelector } from 'react-redux';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom';
import { CirclePhoto } from '@branchpolitics/react.display.circle-photo';
import styled from 'styled-components';
import LogoutIcon from '@mui/icons-material/Logout';
import UserMgmtIcon from '@mui/icons-material/PeopleAlt';

const ProfileMenu = ({
  style,
  className
}) => {
  const navigate = useNavigate();
  const loggedIn = useSelector(state => state?.user?.loggedIn);
  const orgId = useSelector(state => state?.organization?._id);
  const { firstName, photo, organizations } = useSelector(state => state?.user?.data || {});
  const orgPerms = (organizations || []).find(org => `${org.organization}` === `${orgId}`)?.permissions || [];
  /* Show User management */
  const showUserManagement = orgPerms.includes('eb-admin');

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  if(!loggedIn) return null;

  return (
    <>
      <Wrapper onClick={handleClick} style={style} className={className}>
        <CirclePhoto src={photo} size='small'/>
        <Typography variant='body1' style={{ marginLeft: '8px' }}>
          {firstName || 'User'}
        </Typography>
        <ExpandMoreIcon style={{ marginLeft: '8px' }}/>
      </Wrapper>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
      >
        {
          showUserManagement &&
          <MenuItem onClick={() => {
            navigate('/user-management')
            handleClose()
          }}>
            <ListItemIcon>
              <UserMgmtIcon/>
            </ListItemIcon>
            <ListItemText>
              User management
            </ListItemText>
          </MenuItem>
        }
        <MenuItem onClick={() => {
          navigate('/logout')
          handleClose()
        }}>
          <ListItemIcon>
            <LogoutIcon/>
          </ListItemIcon>
          <ListItemText>
            Log out
          </ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

export { ProfileMenu };
