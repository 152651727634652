import { useState } from 'react';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/InfoOutlined';

export const FormField = ({
  label,
  required,
  value,
  name,
  onChange,
  placeholder,
  style,
  styleTextField,
  className,
  classNameTextField,
  longer = false,
  alternateComponent,
  tooltip
}) => {
  return (
    <Wrapper style={style} className={className}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {
          label &&
          <Typography variant='body2' color='textPrimary'>
            {label}
          </Typography>
        }
        {
          tooltip &&
          <Tooltip title={tooltip} placement="top">
            <InfoIcon fontSize='small' style={{ marginLeft: '12px' }}/>
          </Tooltip>
        }
      </div>
      {
        alternateComponent
        ? alternateComponent
        : <TextField
            variant='outlined'
            type='text'
            value={value}
            name={name}
            onChange={onChange}
            placeholder={placeholder || ''}
            {...(longer ? { rows: 4, multiline: true } : {} )}
          />
      }
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`
