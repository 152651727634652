import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFeathers } from '@branchpolitics/react.util.feathers';
import { logout } from '../../state/actions';

const Logout = ({

}) => {
  const dispatch = useDispatch();
  const { loggedIn } = useSelector(state => state.user);
  const feathers = useFeathers();

  useEffect(() => {
    if(feathers) signOut();
  }, [ feathers ])


  const signOut = async () => {
    try {
      await feathers.logout()
    } catch(err) {
      console.log(`error logging out...`, err)
    } finally {
      dispatch(logout())
    }
  }

  if(!loggedIn) {
    return <Navigate to='/'/>
  }

  return null;
}

export default Logout;
