import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useFormInputs } from '../../util';
import { useFeathers } from '@branchpolitics/react.util.feathers';
import { setUser } from '../../state/actions';
import { useSelector, useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import {
  PasswordField,
  PunchListItem,
  EmailField,
} from '../../components';
import { useNavigate, useLocation, Link } from 'react-router-dom';

const SignUp = ({

}) => {
  // const from = (useLocation() || {}).state?.from;
  const redirectTo = '/dashboard';
  const loggedIn = useSelector(state => state?.user?.loggedIn)
  const feathers = useFeathers();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [ loading, setLoading ] = useState(false);
  const [ error, setError ] = useState(null);
  const [ formInputs, onChange ] = useFormInputs({
    email: '',
    password: ''
  })

  const reqPasswordLength = formInputs?.password?.length >= 6;
  const reqPasswordUppercase = /[A-Z]/g.test(formInputs?.password || '')
  const reqPasswordSpecial = /[-!$%^&*()_+|~=`{}\[\]:";'<>?,.\/\d]/g.test(formInputs?.password || '')
  const submittable =
    reqPasswordLength &&
    reqPasswordUppercase &&
    reqPasswordSpecial &&
    formInputs?.email;

  const create = async () => {
    if(!submittable || loading) return;

    setLoading(true)
    setError(null)
    try {
      const user = await feathers.getService('users').create({
        ...formInputs
      })

      const authRes = await feathers.authenticate({
        password: formInputs.password,
        email: formInputs.email,
        strategy: 'local'
      })

      dispatch(setUser(user))
    } catch(err) {
      setError(err)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if(loggedIn) navigate(redirectTo)
  }, [ loggedIn ])

  let formattedError, errorEmail;
  if(error) {
    switch(error.code) {
      case 409:
        formattedError = 'There is already an account with this email.';
        errorEmail = true;
        break;
      case 500:
        formattedError = 'Our servers are having an issue. Please try again.';
        break;
      default:
        formattedError = 'Could not create account. Please check your inputs and try again.';
        break;
    }
  }

  return (
    <Wrapper>
      <Typography variant='h1'>Welcome to<br/>Endorsement Builder</Typography>
      <Typography variant='body1' style={{ marginTop: '8px', marginBottom: '24px' }}>Create your account to get started</Typography>
      {
        error &&
        <Alert severity="warning" style={{ marginBottom: '24px' }}>
          <Typography variant='body1'>
            {formattedError}{` `}{errorEmail && <Link to='/login'>Sign in</Link>}
          </Typography>
        </Alert>
      }
      <EmailField
        value={formInputs.email}
        name='email'
        onChange={onChange}
        style={{ marginBottom: '12px' }}
      />
      <PasswordField
        value={formInputs.password}
        name='password'
        onChange={onChange}
        style={{ marginBottom: '24px' }}
      />
      <Typography variant='body1' color='textPrimary' style={{ fontWeight: 'bold' }}>
        Password must:
      </Typography>
      <PunchListItem style={{ marginTop: '2px' }} complete={reqPasswordLength}>
        Be at least six characters long
      </PunchListItem>
      <PunchListItem style={{ marginTop: '2px' }} complete={reqPasswordUppercase}>
        Have at least one uppercase letter
      </PunchListItem>
      <PunchListItem style={{ marginTop: '2px' }} complete={reqPasswordSpecial}>
        Have at least one symbol or number
      </PunchListItem>
      <Button
        color='primary'
        variant='contained'
        fullWidth
        style={{ marginTop: '24px'}}
        disabled={!submittable || loading}
        size='large'
        onClick={create}
      >
        Sign up
      </Button>
      <Typography
        variant='body1'
        style={{ marginTop: '24px'}}
        color='textPrimary'
      >
        Already have an account with us? <Link to='/login'><u><b>Sign in</b></u></Link>
      </Typography>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 48px;
`

export default SignUp;
