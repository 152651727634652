import React, { useState, useLayoutEffect, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';


function useWindowDimensions() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

function useFormInputs(
  defaultInputs = {},
) {
  const [ formInputs, setFormInputs ] = useState(defaultInputs);

  const reset = () => setFormInputs(defaultInputs)
  const onChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    const updateOp = {};
    updateOp[name] = value;
    const newInputs = {
      ...formInputs,
      ...updateOp
    }
    setFormInputs(newInputs);
  }

  return [ formInputs, onChange, reset ];
}

function useHasChanged(val, equalFunction) {
  const prevVal = usePrevious(val)
  if(!prevVal && val) return true;
  if(!val && prevVal) return true;
  if(equalFunction) {
    return !equalFunction(prevVal, val)
  } else {
    return prevVal !== val
  }
}

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function isPermitted(user, permissions) {
  if(!permissions) throw new Error('No permissions provided. Provide an empty array.')
  if(permissions.length === 0) return true;
  if(!user.permissions || user.permissions.length === 0) return false;

  return user.permissions.some(p => permissions.includes(p));
}

function isOrgPermitted(user, permissions, organizationId) {
  if(!permissions) throw new Error('No permissions provided. Provide an empty array.')
  if(!organizationId) throw new Error('No organizationId provided. Provide an empty array.')
  if(permissions.length === 0) return true;

  const permissionsForOrgs = (user.organizations || []).find(o => o.organization === organizationId);
  if(!permissionsForOrgs || !(permissionsForOrgs.permissions?.length > 0)) return false;

  return (permissionsForOrgs.permissions || []).some(p => permissions.includes(p));
}

const formatMoney = (number) => {
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  return formatter.format(number && !isNaN(number) ? number : 0)
}

export {
  useWindowDimensions,
  useFormInputs,
  useHasChanged,
  isPermitted,
  isOrgPermitted,
  formatMoney,
  usePrevious
};
