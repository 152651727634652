import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useFormInputs } from '../../util';
import { useFeathers } from '@branchpolitics/react.util.feathers';
import { setUser, setOrganization, setGuide } from '../../state/actions';
import { useSelector, useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import {
  PasswordField,
  EmailField,
  PunchListItem
} from '../../components';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { OAuthButton } from '@branchpolitics/react.input.oauth-button';
import qs from 'qs';

const redirectToExternal = (redirectTo, authToken) => {
  const queryParams = qs.stringify({ authToken });
  const newLocation = `${redirectTo}?${queryParams}`

  console.log('Auth successful... going to', newLocation)
  window.location = newLocation
}

const SignIn = ({

}) => {
  const redirectTo = '/dashboard';
  const loggedIn = useSelector(state => state?.user?.loggedIn)
  const feathers = useFeathers();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [ loading, setLoading ] = useState(false);
  const [ error, setError ] = useState(null);
  const [ formInputs, onChange ] = useFormInputs({
    email: '',
    password: ''
  })
  const {
    redirectTo: redirectToParams
  } = qs.parse(location.search, { ignoreQueryPrefix: true });

  const submittable = formInputs?.password && formInputs?.email;

  const create = async () => {
    if(!submittable || loading) return;

    setLoading(true)
    setError(null)
    try {
      const authRes = await feathers.authenticate({
        password: formInputs.password,
        email: formInputs.email,
        strategy: 'local'
      })

      if(redirectToParams) {
        redirectToExternal(redirectToParams, authRes.accessToken)
      } else {
        const org = (authRes?.user?.organizations || []).find(o => (o.permissions || []).some(p => ['eb-admin','eb-editor','eb-viewer'].includes(p)));
        if(org) {
          const organizationsRes = await feathers.getService('organizations').find({ query: { _id: org.organization }});

          if(organizationsRes.data?.length > 0) {
            const orgDetailRes = await feathers.getService('organizations').get(organizationsRes.data[0].key, { query: { $endorsementGuide: true }});
            dispatch(setOrganization(orgDetailRes))
            dispatch(setGuide(orgDetailRes?.endorsementGuide))
          }
        }
        dispatch(setUser(authRes.user))
      }
    } catch(err) {
      setError(err)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if(loggedIn) {
      // if we're logged in but we have a redirect request, send back the auth token
      if(redirectToParams) {
        redirectAuthToken()
      } else {
        navigate(redirectTo)
      }
    }
  }, [ loggedIn ])

  const redirectAuthToken = async () => {
    const authToken = await feathers.authentication.getSessionToken();
    redirectToExternal(redirectToParams, authToken)
  }

  let formattedError;
  if(error) {
    switch(error.code) {
      case 404:
        formattedError = `We couldn't find an account for that username and password combination.`;
        break;
      case 500:
        formattedError = 'Our servers are having an issue. Please try again.';
        break;
      default:
        formattedError = 'Log in failed. Please check your inputs and try again.';
        break;
    }
  }

  return (
    <Wrapper>
      <Typography variant='h1' style={{ marginBottom: '36px' }}>Welcome back</Typography>
      {
        error &&
        <Alert severity="warning" style={{ marginBottom: '24px' }}>
          <Typography variant='body1'>
            {formattedError}
          </Typography>
        </Alert>
      }
      <EmailField
        value={formInputs.email}
        name='email'
        onChange={onChange}
        style={{ marginBottom: '12px' }}
      />
      <PasswordField
        value={formInputs.password}
        name='password'
        onChange={onChange}
        style={{ marginBottom: '16px' }}
      />
      <Button
        color='primary'
        variant='contained'
        fullWidth
        style={{ marginTop: '24px'}}
        disabled={!submittable || loading}
        size='large'
        onClick={create}
      >
        Sign in
      </Button>
      <Typography
        variant='body1'
        style={{ marginTop: '24px'}}
        color='textPrimary'
      >
        Don't have an account with us? <Link to='/sign-up'><u><b>Sign up</b></u></Link>
      </Typography>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 48px;
`

export default SignIn;
