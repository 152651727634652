import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

export const FormSaveButtons = ({
  onCancel,
  onSave,
  style,
  className
}) => {
  return (
    <ButtonWrapper style={style} className={className}>
      <Typography variant='body1' style={{ cursor: 'pointer' }} onClick={onCancel}>
        <u>Cancel</u>
      </Typography>
      <Button variant='contained' style={{ marginLeft: '36px'}} color='primary' size='large' onClick={onSave}>
        Save
      </Button>
    </ButtonWrapper>
  );
}

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`
