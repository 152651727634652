import React, { useState, useEffect } from 'react';
import { useFeathers } from '@branchpolitics/react.util.feathers';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setGuide } from '../../../state/actions';
import config from '../../../config';

import styled from 'styled-components';
import { TwoPane } from '@branchpolitics/react.layout.two-pane';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { LinkShare } from '../../../components';

export const Launch = ({ }) => {
  const guide = useSelector(state => state.guide)

  return (
    <TwoPane style={{ maxHeight: '100%', height: '100%', margin: '0 auto 0', maxWidth: '800px' }}>
      <Left style={{ width: '60%', display: 'flex', alignItems: 'stretch', flexDirection: 'column' }}>
        <Typography variant='h1' color='primary'>
          Get ready to go live!
        </Typography>
        <Typography variant='body1' style={{ marginRight : '16px', marginTop:'16px'}}>
           Branch handles payment through a secure, third-party provider. Your page will go live 1-2 days after you make your first payment. In the meantime, you'll have access to a preview version of your page.
        </Typography>
        <div style={{ maxWidth: '300px', display: 'flex', flexDirection: 'column', alignItems: 'stretch', marginTop: '60px' }}>
          <a href={config.stripePaymentLink}>
            <Button
              variant='contained'
              color='primary'
              size='large'
              style={{ height: '50px', marginBottom: '20px' }}
              fullWidth
            >
              Complete payment
            </Button>
          </a>
          <a href={guide?.link} target='_blank'>
            <Button
              variant='outlined'
              color='primary'
              size='large'
              style={{ height: '50px' }}
              fullWidth
            >
              Open guide preview
            </Button>
          </a>
        </div>

      </Left>
      <Right style={{ width: '40%' }}>
        <RightInner>
          <Typography variant='body1' style={{ color: '#FFFFFF', fontWeight: 'bold'}}>
            Your monthly payment
          </Typography>
          <Typography variant='h1' style={{ color: '#FFFFFF', fontWeight: 'bold', marginTop: '32px' }}>
            $199.00
          </Typography>
          <Typography variant='body1' style={{ color: '#FFFFFF', marginTop: '60px', textAlign: 'center' }}>
            Once you complete payment, your guide will be live within 1-2 days
          </Typography>
        </RightInner>
      </Right>
    </TwoPane>
  )
}

const Wrapper = styled.div`
  height: 100%;
  max-height: 100%;
`

const Right = styled.div`
  display: flex;
  flex-direction: column;
`
const Left = styled.div`
  padding-right: 48px;
  max-height: 100%;
  overflow-y: scroll;
`

const RightInner = styled.div`
  background: linear-gradient(149.55deg, #22034F 4.33%, rgba(69, 61, 183, 0.99) 96.78%);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 36px;
`
