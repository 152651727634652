import { useState } from 'react';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import OutlinedInput from '@mui/material/OutlinedInput';

export const EmailField = ({
  value,
  name,
  onChange,
  disabled,
  style
}) => {
  return (
    <OutlinedInput
      variant='outlined'
      id="outlined-adornment-email"
      type={'text'}
      value={value}
      style={style}
      disabled={disabled}
      name={name}
      onChange={onChange}
      startAdornment={
        <InputAdornment position="start">
          <MailOutlineOutlinedIcon />
        </InputAdornment>
      }
      placeholder='Email'
    />
  )
}
