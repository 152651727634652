import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useFeathers } from '@branchpolitics/react.util.feathers';

import styled from 'styled-components';
import { TwoPane } from '@branchpolitics/react.layout.two-pane';
import OrgIcon from '@mui/icons-material/AccountBalance';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment';
import RemoveIcon from '@mui/icons-material/DeleteOutline';
import IconButton from '@mui/material/IconButton';
import ViewIcon from '@mui/icons-material/Visibility';
import { Modal } from '@branchpolitics/react.display.modal';
import { FormField, EmailField } from '../../components';
import { useFormInputs } from '../../util';

const UserManagement = ({}) => {
  const feathers = useFeathers();
  const navigate = useNavigate();
  const organization = useSelector(state => state.organization);
  const { firstName, lastName, email, photo, organizations } = useSelector(state => state?.user?.data || {});
  const orgPerms = (organizations || []).find(org => `${org.organization}` === `${organization._id}`)?.permissions || [];

  const showUserManagement = orgPerms.includes('eb-admin');

  const [ modalShowing, setModalShowing ] = useState(false);
  const [ users, setUsers ] = useState(undefined);
  const [ loading, setLoading ] = useState(true);
  const [ error, setError ] = useState(null);

  // For adding new users
  const [ formInputs, onChange, resetFormInputs ] = useFormInputs({
    email: '',
    editAccess: false
  })
  const submittable = formInputs?.email?.length > 5;

  useEffect(() => {
    if(!showUserManagement) navigate('/')
    else loadUsers()
  }, [])

  const addUserToOrg = async () => {
    if(!submittable || loading) return;
    setLoading(true)
    try {
      const result = await feathers.getService('invite-links').create({
        organization: organization.key,
        email: formInputs?.email,
        starterPermissions: [ formInputs?.editAccess ? 'eb-editor' : 'eb-viewer']
      })
      console.log('result', result)
      loadUsers()
      resetFormInputs()
      setModalShowing(false)
    } catch(err) {
      console.error(err)
      setError(err)
    } finally {
      setLoading(false)
    }
  }

  // a dictionary of 'staged' edit permissions, by user ID
  const [ tempEditPerms, setTempEditPerms ] = useState({});
  const editUserPermissionsForOrg = async (userId, giveEditPerms) => {
    const update = {};
    update[userId] = giveEditPerms;
    setTempEditPerms({
      ...tempEditPerms,
      ...update
    })

    try {
      const result = await feathers.getService('users').patch(userId, {
        organizations: {
          organization: `${organization._id}`,
          permissions: [giveEditPerms ? 'eb-editor' : 'eb-viewer']
        }
      })
      loadUsers()
      throw new Error()
    } catch(err) {
      console.error(err)
      setError(err)
      // revert the failure
      update[userId] = !giveEditPerms;
      setTempEditPerms({
        ...tempEditPerms,
        ...update
      })
    }
  }

  const removeUserFromOrg = async (userId) => {
    try {
      const result = await feathers.getService('users').patch(userId, {
        organizations: {
          organization: `${organization._id}`,
          action: 'remove'
        }
      })
      loadUsers()
    } catch(err) {
      console.error(err)
      setError(err)
    }
  }

  const loadUsers = async () => {
    try {
      const users = await feathers.getService('users').find({
        query: {
          organization: organization._id,
          $limit: 50
        }
      })

      const invites = await feathers.getService('invite-links').find({
        query: {
          organization: organization._id,
          valid: true,
          $limit: 50,
          $sort: { createdAt: -1 }
        }
      })

      const invitesReduced = Object.values((invites.data || []).reduce((acc, i) => {
        const update = {};
        update[i.email] = i;
        return { ...update, ...acc }
      }, {}))

      const emailsCreated = (users.data || []).map(i => i.email);

      const usersAgged = [].concat(
        (users.data || []).map(u => ({
          isInvite: false,
          _id: u._id,
          isAdmin: (u.organizationPermissions || []).includes('eb-admin'),
          email: u.email,
          editPerms: (u.organizationPermissions || []).some( p => ['eb-editor','eb-admin'].includes(p)),
          createdAt: u.createdAt
        })),
        (invitesReduced).map(i => ({
          isInvite: true,
          _id: i._id,
          email: i.email,
          editPerms: (i.starterPermissions || []).includes('eb-editor'),
          sentAt: i.createdAt
        })).filter(i => !emailsCreated.includes(i.email))
      )

      setTempEditPerms({})
      setUsers(usersAgged)
    } catch(err) {
      console.log(err)
      setError(err)
    } finally {
      setLoading(false);
    }
  }

  const displayUsers = () => {
    const columns = ['Email','Edit permissions','Status', 'Actions'];
    return (
      <UsersTable>
        <UsersHeader numColumns={columns.length}>
          {columns.map(c => <Typography variant='body1' key={c}><b>{c}</b></Typography>)}
        </UsersHeader>
        <UsersBody numColumns={columns.length}>
          {users.map(u => (
            <React.Fragment key={u?.email}>
              <div className='users-row'><Typography variant='body1'>{u.email}</Typography></div>
              <div className='users-row'><Typography variant='body1'>{
                u.isInvite
                ? (u.editPerms ? 'Can edit' : 'Read only')
                : <Switch checked={tempEditPerms[u._id] ?? u.editPerms} disabled={u.isAdmin} onChange={() => editUserPermissionsForOrg(u._id, !u.editPerms)}/>
              }</Typography></div>
              <div className='users-row'><Typography variant='body1'>{
                u.isInvite
                ? (<i>Invite pending</i>)
                : `User since ${moment(u.createdAt).format('LL')}`
              }</Typography></div>
              <div className='users-row'>
                {
                  !u.isAdmin && !u.isInvite &&
                  <IconButton onClick={() => removeUserFromOrg(u._id)}>
                    <RemoveIcon />
                  </IconButton>
                }
              </div>
            </React.Fragment>
          ))}
        </UsersBody>
      </UsersTable>
    )
  }

  return (
    <Wrapper>
      <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', alignItems: 'center', width: '100%', flex: 1 }}>
          <OrgIcon style={{ marginRight: '8px' }}/>
          <Typography variant='h3'>
            {organization?.name} users
          </Typography>
        </div>
        <Button
          variant='contained'
          color='primary'
          onClick={() => setModalShowing(true)}
        >
          Add user
        </Button>
      </div>
      {
        typeof(users) === 'undefined'
        ? <CircularProgress/>
        : displayUsers()
      }
      <Modal
        open={modalShowing}
        onClose={() => setModalShowing(false)}
        title='Add new user'
      >
        <form style={{ display : 'flex', flexDirection: 'column' }}>
          <Typography variant='body1' style={{ marginBottom: '16px' }}>
            To add someone to your team, fill in their email address and click Send. We'll send them an invite link, which they can use to set up an Endorsement Builder account and join your organization.
          </Typography>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FormField
              label='Email'
              style={{ flex: 1 }}
              alternateComponent={
                <EmailField
                  value={formInputs.email}
                  name='email'
                  onChange={onChange}
                />
              }
            />
            <FormField
              label='Grant edit access'
              style={{ marginLeft: '48px' }}
              tooltip='Users with edit access can add, change, or remove endorsements. Users without edit permissions will have read-only access.'
              alternateComponent={
                <Switch
                  checked={formInputs.editAccess}
                  name='editAccess'
                  onChange={(e) => onChange({ target: { name: e.target.name, value: e.target.checked}})}
                />
              }
            />
          </div>
          <Button
            style={{ marginTop:'24px', alignSelf: 'flex-end'}}
            color='primary'
            disabled={!submittable || loading}
            onClick={addUserToOrg}
            variant='contained'
          >
            {loading ? 'Sending...' : 'Send invite'}
          </Button>
        </form>
      </Modal>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 0 auto 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  max-height: 100%;
  overflow-y: scroll;
`

const UsersTable = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 36px;
`

const UsersHeader = styled.div`
  width: 100%;
  border-bottom: solid 1px #CCCCCC;
  padding: 12px 0 12px;
  display: grid;
  grid-template-columns: repeat(${props => props.numColumns - 1}, 1fr) auto;
  grid-gap: 12px;
`

const UsersBody = styled.div`
  width: 100%;
  margin: 12px 0 12px;
  display: grid;
  grid-gap: 12px;
  grid-template-columns: repeat(${props => props.numColumns - 1}, 1fr) auto;
  align-items: center;

  & > div.users-row {
    padding: 12px 0;
  }
`

export default UserManagement;
