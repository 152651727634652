import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setGuide } from '../../state/actions';
import { useFeathers } from '@branchpolitics/react.util.feathers';
import { isOrgPermitted } from '../../util';

import styled from 'styled-components';
import { TwoPane } from '@branchpolitics/react.layout.two-pane';
import { MobileFrame } from '@branchpolitics/react.display.mobile-frame';
import { StatusTag } from '@branchpolitics/react.display.status-tag';
import { EndorsementGuide, propsFromServerObjects } from '@branchpolitics/react.pages.endorsement-guide';
import OrgIcon from '@mui/icons-material/AccountBalance';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import { ShareGuide, ActionTile, ConfirmDialog } from '../../components';

import ViewIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import PreviewIcon from '@mui/icons-material/OpenInNew';

const Dashboard = ({}) => {
  const dispatch = useDispatch();
  const feathers = useFeathers();
  const navigate = useNavigate();
  const guide = useSelector(state => state.guide);
  const organization = useSelector(state => state.organization);
  const user = useSelector(state => state.user?.data);
  const guidePreview = (guide && organization) ? propsFromServerObjects(guide, organization) : null;

  // determine whether the user has appropriate permissions
  const editPermissions = isOrgPermitted(user, ['eb-editor','eb-admin'], organization._id);

  // if true, will show a modal for switching the enabled status of the
  const [ confirmDialogEnabled, setConfirmDialogEnabled ] = useState(false);
  const [ saving, setSaving ] = useState(false);

  useEffect(() => {
    if(!organization) navigate('/')
    if(!guide) navigate('/guide/start')
  }, [])

  const confirmEnableSwitch = async () => {
    if(saving) return;
    // confirmed with the user, make the change on the server
    setSaving(true)
    try {
      const result = await feathers.getService('endorsement-guides').patch(
        guide?._id,
        { status: guide?.status === 'inactive' ? 'live' : 'inactive' }
      )

      dispatch(setGuide(result))
    } catch(err) {
      console.log('There was an error')
      console.log(err)
    } finally {
      setSaving(false)
      setConfirmDialogEnabled(false)
    }
  }

  const status = {
    'draft': { color: 'grey', text: 'Draft' , showCopyLink: true, showAnalytics: false, showDisable: false, stillEditing: true },
    'pending': { color: 'orange', text: 'Pending', showCopyLink: true, showAnalytics: true, showDisable: false, description: 'Your landing page is currently awaiting review. For an update on the status, you can email us at hello@branch.chat.' },
    'live': { color: 'green', text: 'Live', showCopyLink: true, showAnalytics: true, showDisable: true },
    'inactive': { color: 'red', text: 'Disabled' , showCopyLink: true, showAnalytics: true, showDisable: true, description: 'Your landing page is currently disabled. To make your guide accessible to voters again, click the switch to the right.' },
  }[guide?.status || 'draft']

  return (
    <Wrapper>
      <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <OrgIcon style={{ marginRight: '8px' }}/>
        <Typography variant='h3'>
          {organization?.name}
        </Typography>
      </div>
      <TwoPane style={{ maxWidth: status?.showAnalytics ? '1000px' : '900px' }}>
        <Left style={{ width: '60%', paddingRight: '80px', paddingLeft: '8px' }}>
          <LeftMain>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant='h1' color='textSecondary' style={{ fontSize: '26px' }}>Landing page</Typography>
            </div>
            {/*<div style={{ width: '100%', maxWidth: '450px', backgroundColor: '#898989', height: '1px', marginBottom: '26px' }} />*/}
            <Typography variant='body1' style={{ fontSize: '16px', marginTop: status?.showAnalytics ? '8px' : '20px' }}>
              Highlight your endorsed candidates {!status?.showAnalytics && <br/>}in a beautiful, interactive landing page
            </Typography>
            {
              status?.description &&
              <div style={{ marginTop: '24px', maxWidth: '400px' }}>
                <Typography variant='h3' color='textSecondary'>Status</Typography>
                <Typography variant='body1' color='textPrimary' style={{ marginBottom: '12px' }}>
                  {status?.description}
                </Typography>
              </div>
            }
            {
              status.stillEditing &&
              <Link to='/guide/home' style={{ marginTop: '16px'}}>
                <Button color='primary' variant='contained' size='large' style={{ minWidth: '200px' }}>
                  Continue editing
                </Button>
              </Link>
            }
            <div style={{ height: '48px', width: '1px'}}/>
            <Typography variant='h3' color='textSecondary'>Share</Typography>
            {
              status.showCopyLink &&
              <ShareGuide style={{ maxWidth: '400px' }}/>
            }

          </LeftMain>
        </Left>
        <div style={{ width: '40%', display: 'flex', justifyContent: 'center'  }}>
          <div style={{ paddingTop: '24px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {
              guidePreview &&
              <>
                {
                  status &&
                  <StatusTag
                   status={status?.color}
                   text={status?.text}
                   style={{ marginBottom: '-18px', zIndex: 1, alignSelf: 'center', boxShadow: '0px 4px 4px rgba(0,0,0,0.08)' }}
                  />
                }
                <MobileFrame scaleBy={0.7} height={600}>
                  <EndorsementGuide {...guidePreview}/>
                </MobileFrame>
              </>
            }
          </div>
          {
            /* buttons for interacting with the guide */
            status?.showAnalytics &&
            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '20px', paddingTop: '20px' }}>
              <ActionTileStyled label='views' style={{ marginBottom: '-8px'}}>
                <ViewIcon />
                <Typography variant='h3' style={{ marginTop: '8px', marginBottom: '-6px', color: '#878787' }}>{guide?.analytics?.views}</Typography>
              </ActionTileStyled>
              {
                editPermissions &&
                <ActionTileStyled label='Edit' onClick={() => navigate('/guide/home')}>
                  <EditIcon/>
                </ActionTileStyled>
              }
              <ActionTileStyled label='View' onClick={{ href: guide?.link }}>
                <PreviewIcon/>
              </ActionTileStyled>
              {
                status?.showDisable && editPermissions &&
                <ActionTileStyled label={guide?.status === 'inactive' ? 'Activate' : 'Deactivate'} onClick={{ href: guide?.link }}>
                  <Switch checked={guide?.status === 'live'} onChange={(e) => setConfirmDialogEnabled(true)}/>
                </ActionTileStyled>
              }
            </div>
          }
        </div>
      </TwoPane>
      <ConfirmDialog
        open={confirmDialogEnabled}
        onConfirm={confirmEnableSwitch}
        onClose={() => setConfirmDialogEnabled(false)}
        title={guide?.status === 'inactive' ? 'Resume your landing page?' : 'Disable your landing page?'}
      >
        {
          guide?.status === 'inactive'
          ? `If you do this, monthly billing will resume and voters will have access to your landing page`
          : `If you do this, monthly billing will pause and voters will no longer have access to your landing page.`
        }
      </ConfirmDialog>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 24px 64px;
  width: 100%;
  min-height: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Left = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  align-items: center;
`

const LeftMain = styled.div`
  display: flex;
  flex-direction: column;
`

const ActionTileStyled = styled(ActionTile)`
  margin-top: 24px;
`


export default Dashboard;
