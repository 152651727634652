import { useState } from 'react';
import { useFeathers } from '@branchpolitics/react.util.feathers';
import { SearchableSelect } from '@branchpolitics/react.input.searchable-select';
import SearchIcon from '@mui/icons-material/Search';
import { CandidateListItem } from '../../DataDisplay';

const CandidateSearch = ({
  style,
  className,
  election,
  onSelect,
  existingCandidates    // candidates who have already been added to the list
}) => {
  const feathers = useFeathers();

  const [ optionsForField, setOptionsForField ] = useState([]);
  const [ loadingForField, setLoadingForField ] = useState(false);
  const searchForField = async (searchText) => {
    if(!feathers) return;
    setLoadingForField(true);

    const existingCandidateIds = (existingCandidates || []).map(cd => `${cd._id || cd}`);

    const candidates = await feathers.getService('candidates').find({ query: {
      ...(election ? { election } : {}),
      ...(searchText ? { name: { $search: searchText } } : {}),
    }})
    const options = candidates.data.map(candidate => ({
      candidate,
      alreadyAdded: existingCandidateIds.includes(`${candidate._id}`)
    }));

    setOptionsForField(options)
    setLoadingForField(false);
  }

  return (
    <SearchableSelect
      name='district'
      resetSearchTextOnChange={true}
      placeholder={'Search by candidate name'}
      startIcon={<SearchIcon />}
      onChangeSearchText={(searchText) => searchForField(searchText)}
      value={null}
      searchOptionComponent={CandidateListItem}
      onChange={onSelect}
      optionsLoading={loadingForField}
      options={optionsForField || []}
      style={style}
      className={className}
    />
  )
}

export { CandidateSearch }
