import { createTheme } from '@mui/material/styles';

const palette = {
  error: '#F44336',
  info: '#EFF1B6',
  completion: '#99D266',

  fontTitle: ['"HouschkaRoundedAlt"'],
  fontText: ['"HouschkaRoundedAlt"'],

  // Generic primary and accent colors
  primary: '#4F50CC',
  secondary: '#4F50CC',

  buttonPrimary: '#4F50CC',     // Used for most action buttons
  buttonSecondary: '#2C2C2C',   // Used when there are two button options

  // primary text is used almost everywhere, and secondary is used for links
  textPrimary: '#313639',
  textSecondary: '#03047B',
}

export const theme = {
  palette
}

export const muiTheme = createTheme({
  palette: {
    primary: {
      main: theme.palette.buttonPrimary,
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: theme.palette.buttonSecondary,
      contrastText: '#FFFFFF',
    },
    text: {
      primary: theme.palette.textPrimary,
      secondary: theme.palette.textSecondary
    },
    success: {
      main: '#99D266',
    }
  },
  typography: {
    fontFamily: theme.palette.fontText.join(','),
    color: theme.palette.textPrimary,
    h1: {
      fontSize: '28px',
      fontWeight: 'bold',
      fontFamily: theme.palette.fontTitle.join(','),
      ...(theme?.palette?.letterSpacingTitle ? { letterSpacing : theme?.palette?.letterSpacingTitle } : {}),
      ...(theme?.palette?.fontWeightTitle ? { fontWeight : theme?.palette?.fontWeightTitle } : {})
    },
    h2: {
      fontSize: '22px',
      fontWeight: 'bold',
      fontFamily: theme.palette.fontTitle.join(','),
      ...(theme?.palette?.letterSpacingTitle ? { letterSpacing : theme?.palette?.letterSpacingTitle } : {})
    },
    h3: {
      fontSize: '18px',
      fontWeight: 'bold'
    },
    h4: {
      fontSize: '14px',
      fontWeight: 'bold'
    },
    h5: {
      fontSize: '12px',
      opacity: 0.7,
      fontWeight: 'bold'
    },
    body1: {
      fontSize: '16px',
      lineHeight: 1.4,
    },
    body2: {
      fontSize: '13px'
    },
    subtitle1: {
      fontSize: '13px',
      lineHeight: 1.2,
    },
    subtitle2: {
      fontSize: '12px',
      opacity: 0.7,
      lineHeight: 1.1,
    },
    overline: {
      fontSize: '14px',
      letterSpacing: '1px',
      fontWeight: 'bold'
    },
    button: {
      fontFamily: theme.palette.fontText.join(','),
    }
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFFFFF',
        },
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          fontSize: '14px',
        }
      }
    },
    MuiButton: {
      // Disable ripple globally.
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          borderRadius: '4px',
          textTransform: 'none',
          "&:active": {
            transform: 'scale(0.99)',
            boxShadow: 'none',
            opacity: 0.95
          },
          "&.disabled": {
            opacity: 0.5
          },
          fontWeight: 'bold',
          fontSize: '16px',
          letterSpacing: '1px',
        },
        outlinedPrimary: {
          '& > span': {
            color: theme.palette.buttonPrimary,
          }
        },
        outlinedSecondary: {
          '& > span': {
            color: theme.palette.buttonSecondary,
          }
        },
        contained: {
          '& > span': {
            color: '#FFFFFF',
          }
        },
        outlined: {
          backgroundColor: '#FFFFFF'
        },
      }
    },
  }
})
