import styled from 'styled-components';
import Typography from '@mui/material/Typography';

export const ActionTile = ({ children, style, className, onClick, label }) => {
  return (
    <Wrapper
      style={style}
      className={className}
      clickable={onClick ? 1 : 0}
      {...(onClick?.href ? { as: 'a', href: onClick.href, target: '_blank' } : { onClick })}
    >
      {children}
      {label && <Typography variant='body1' style={{ color: '#878787', marginTop: '4px'}}>{label}</Typography>}
    </Wrapper>
  );
}


const Wrapper = styled.div`
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 65px;
  padding: 6px;

  svg, &:visited, &:hover, &:active { color: #878787 };

  ${props => props.clickable ? `
    cursor: pointer;
    &:hover {
      background-color: #EEEEEE;
    }
  ` : ``}
`
