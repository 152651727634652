export const SET_USER = 'SET_USER';
export function setUser(response) {
  return { type: SET_USER, response };
}

export const SET_ORGANIZATION = 'SET_ORGANIZATION';
export const setOrganization = (response) => ({ type: SET_ORGANIZATION, response });


export const SET_GUIDE = 'SET_GUIDE';
export const setGuide = (response) => ({ type: SET_GUIDE, response });

export const USER_LOGOUT = 'USER_LOGOUT';
export function logout() {
  return{ type: USER_LOGOUT };
}
