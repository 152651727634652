import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const LinkShare = ({
  style,
  className,
  onCopy,
  children
}) => {
  const [ copiedText, setCopiedText ] = useState(false);

  useEffect(() => {
    if(copiedText) {
      if(onCopy) onCopy()
      const timer = setTimeout(() => setCopiedText(false), 2000);
      return () => clearTimeout(timer)
    }
  }, [ copiedText ])

  return (
    <div style={{ display: 'flex', alignItems: 'stretch', justifyContent: 'space-between', ...(style || {}) }} className={className}>
      <LinkWrapper>
        <a href={children} target='_blank'>
          <Typography variant='body1' style={{ color: '#FFFFFF', fontWeight: 'bold', textAlign: 'center' }}>
            {children}
          </Typography>
        </a>
      </LinkWrapper>
      <CopyToClipboard text={children} onCopy={() => setCopiedText(true)}>
        <Button
          variant='outlined'
          color='primary'
          style={{
            width: '70px'
          }}
        >
          { copiedText ? 'Copied!' : 'Copy'}
        </Button>
      </CopyToClipboard>
    </div>
  );
}

const LinkWrapper = styled.div`
  padding: 8px 16px;
  margin-right: 16px;
  flex: 1;
  background: linear-gradient(149.55deg, #22034F 4.33%, rgba(69, 61, 183, 0.99) 96.78%);
  display: flex;
  flex-direction: column;
  align-items: stretch;

  p, span {
    cursor: pointer;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
      text-decoration-style: dotted;
    }
  }
`

export { LinkShare };
