import React, { useEffect, useState } from 'react';
import {
  FormField,
  FormSaveButtons,
  CandidateSearch,
  CandidateListItem
} from '../../../components';
import { SearchableList } from '@branchpolitics/react.display.searchable-list';
import styled from 'styled-components';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import IconButton from '@mui/material/IconButton';

/*
  Takes in the current guide object. Returns true if the step is complete, based on the guide
*/
export const step3Complete = (guide) => guide?.candidates?.length > 0;

export const Step3 = ({
  onUpdateGuide,
  onSave,
  onCancel,
  guide
}) => {
  /*
    Set initial inputs from the guide on the server.
    The component is re-initialized each time a step is saved/cancelled
  */
  const [ candidates, setCandidates ] = useState(guide?.candidates || [])

  /**
   * Manages displaying the changes on the guide, in real-time
   * Maps the formInputs of the local component to properties of the EndorsementGuide component
   */
  useEffect(() => {
    onUpdateGuide({ candidates })
  }, [ candidates ])

  /**
   * Called whenever there are updates to save.
   * Maps the formInputs to the formatted data structure to then be saved on the server
   */
  const saveGuideStep = () => {
    const inputsMapped = {
      candidates: candidates.map(cd => `${cd._id}`)
    }
    onSave(inputsMapped)
  }

  const addCandidate = (cd) => {
    if(!cd) return;
    const cdIds = (candidates || []).map(cd => `${cd._id}`);
    if(!cdIds.includes(`${cd._id}`)) setCandidates([].concat((candidates || []), [ cd ]));
  }

  const removeCandidate = (candidate) => {
    if(!candidate) return;
    const cdIds = (candidates || []).map(cd => `${cd._id}`);
    if(cdIds.includes(`${candidate._id}`)) setCandidates((candidates || []).filter(cd => cd._id !== candidate._id));
  }


  return (
    <div style={{ maxWidth: '600px' }}>
      <CandidateSearch
        onSelect={(e) => addCandidate(e?.target?.value?.candidate)}
        existingCandidates={candidates}
        election={guide?.election?.key || guide?.election}
      />
      <SearchableList
        itemNameSingular='Candidate'
        itemNamePlural='Candidates'
        style={{ marginTop: '36px', maxHeight: '300px' }}
      >
        {(candidates || []).map((cd, idx) => (
          <ListItem key={cd._id}>
            <CandidateListItem
              style={{ width: 'auto', flex: 1 }}
              candidate={cd}
            />
            <IconButton onClick={() => removeCandidate(cd)}>
              <DeleteIcon />
            </IconButton>
          </ListItem>
        ))}
      </SearchableList>
      <FormSaveButtons
        style={{ marginTop: '36px' }}
        onCancel={onCancel}
        onSave={saveGuideStep}
      />
    </div>
  )
}

const FormFieldStyled = styled(FormField)`
  margin-bottom: 16px;
  max-width: 500px;
`

const ListItem = styled.div`
  padding: 6px 0;
  display: flex;
  align-items: center;
`
