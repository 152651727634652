import styled from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';

const FullScreenLoad = styled.div`
  width: 100%;
  height: 100%;
  background-color: #F7F9FE;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const LoadingPage = ({
  style,
  className
}) => {
  return (
    <FullScreenLoad style={style} className={className}>
      <CircularProgress/>
    </FullScreenLoad>
  )
}
