import React, { useEffect, useState } from 'react';
import {
  FormSaveButtons,
  FormField,
  CandidateSearch,
  CandidateEditCell
} from '../../../components';
import { SearchableList } from '@branchpolitics/react.display.searchable-list';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import CancelIcon from '@mui/icons-material/CancelOutlined';
import AddIcon from '@mui/icons-material/AddOutlined';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

/*
  Takes in the current guide object. Returns true if the step is complete, based on the guide
*/
export const step4Complete = (guide) => guide?.overrides?.length > 0;

const readMoreOptions = [
  { label: 'Candidate website', value: 'website' },
  { label: 'www.Branch.vote', value: 'branch' },
  { label: 'Custom', value: 'custom' },
  { label: 'None', value: 'none' },
]

export const Step4 = ({
  onUpdateGuide,
  onSave,
  onCancel,
  guide,
  organization
}) => {
  /*
    Set initial inputs from the guide on the server.
    The component is re-initialized each time a step is saved/cancelled.
  */
  const [ scoresEnabled, setScoresEnabled ] = useState(typeof(guide?.configuration?.scoresEnabled) === 'boolean' ? guide.configuration.scoresEnabled : true);
  const [ badgesEnabled, setBadgesEnabled ] = useState(typeof(guide?.configuration?.badgesEnabled) === 'boolean' ? guide.configuration.badgesEnabled : false);
  const [ segmentCandidates, setSegmentCandidates ] = useState(typeof(guide?.configuration?.segmentCandidates) === 'boolean' ? guide.configuration.segmentCandidates : false);
  const showBadgesOption = organization?.key === 'fairfight';
  const showSegmentOption = true;
  const [ readMoreTo, setReadMoreTo ] = useState(
    guide?.configuration?.linkToBranch
    ? 'branch'
    : ( guide?.configuration?.linkToCandidate
        ? 'website'
        : (guide?.configuration?.linkToCustom ? 'custom' : 'none' )
    )
  )
  const [ candidates, setCandidates ] = useState(guide?.candidates || [])
  const [ overrides, setOverrides ] = useState(guide?.overrides || [])
  console.log('guide?.overrides', overrides)

  // these are overrides that have been confirmed and are ready to be saved
  const [ stagedOverrides, setStagedOverrides ] = useState(guide?.overrides || [])
  const [ editingStatuses, setEditingStatuses ] = useState({});
  const [ adding, setAdding ] = useState(false);

  // this is to manage the searching behavior of the list
  const [ searchText, setSearchText ] = useState('');
  const filteredCandidates = searchText && searchText?.length > 0
    ? (candidates || []).filter(cd => cd.name.toLowerCase().includes(searchText.toLowerCase()))
    : candidates;

  /**
   * Manages displaying the changes on the guide, in real-time
   * Maps the formInputs of the local component to properties of the EndorsementGuide component
   */
  useEffect(() => {
    const candidatesNew = (candidates || []).map(cd => {
      const override = (overrides || []).find(o => o?.candidateId === cd?._id);
      if(!override) return cd;
      return {
        ...cd,
        ...( override?.score ? { score: override?.score } : {}),
        ...( override?.explanation ? { explanation:override?.explanation } : {}),
        ...( override?.photo ? { photoPathFace: override?.photo } : {}),
        ...( override?.link ? { link: override?.link } : {}),
        ...( override?.starred ? { starred: override?.starred } : {})
      }
    })

    const configuration = {
      linkToBranch: readMoreTo === 'branch',
      linkToCandidate: readMoreTo === 'website',
      linkToCustom: readMoreTo === 'custom',
      scoresEnabled,
      badgesEnabled,
      segmentCandidates
    }
    onUpdateGuide({ candidates: candidatesNew, ...configuration })
  }, [ candidates, overrides, scoresEnabled, readMoreTo, segmentCandidates, badgesEnabled ])

  /**
   * Called whenever there are updates to save.
   * Maps the formInputs to the formatted data structure to then be saved on the server
   */
  const saveGuideStep = () => {
    const inputsMapped = {
      candidates: candidates.map(cd => `${cd._id}`),
      overrides: (stagedOverrides || []),
      configuration: {
        linkToBranch: readMoreTo === 'branch',
        linkToCandidate: readMoreTo === 'website',
        linkToCustom: readMoreTo === 'custom',
        scoresEnabled,
        badgesEnabled,
        segmentCandidates
      }
    }

    console.log(inputsMapped)
    onSave(inputsMapped)
  }


  /*
    Below are the helper functions called when managing all the ins and outs of our component state
  */
  const addCandidate = (cd) => {
    if(!cd) return;
    const cdIds = (candidates || []).map(cd => `${cd._id}`);
    if(!cdIds.includes(`${cd._id}`)) setCandidates([].concat((candidates || []), [ cd ]));
  }

  // removes a candidate from the list entirely
  const removeCandidate = (candidate) => {
    if(!candidate) return;
    const cdIds = (candidates || []).map(cd => `${cd._id}`);
    if(cdIds.includes(`${candidate._id}`)) setCandidates((candidates || []).filter(cd => cd._id !== candidate._id));
  }

  // called everytime an edit is made to a candidate, of any sort
  const overrideChanged = (override) => {
    if(!override) return;
    // all overrides except the one in question
    const overridesBase = (overrides || []).filter(o => o.candidateId !== override.candidateId);
    const overrideBeforeEdit = (overrides || []).find(o => o.candidateId === override.candidateId);

    setOverrides([].concat(overridesBase, [ override ]))

    // We need to do some special logic here
    // If this edit was to the "starred" field, then also make an edit straight to the staged overrides
    if(Boolean(overrideBeforeEdit?.starred) !== Boolean(override?.starred)) {
      // all overrides except the one in question
      const overridesBase2 = (stagedOverrides || []).filter(o => o.candidateId !== override.candidateId);
      setStagedOverrides([].concat(overridesBase2, [ override ]))

      console.log([].concat(overridesBase2, [ override ]))
    }
  }

  // resets a candidate to their state before we edited
  const resetOverrideForCandidate = (candidateId) => {
    const previousOverride = (stagedOverrides || []).find(o => o.candidateId === candidateId);
    if(previousOverride) overrideChanged(previousOverride)
    else overrideChanged({ candidateId }) // blank one
  }

  // persists the current override for a candidate
  const stageOverrideForCandidate = (candidateId) => {
    const unstagedOverride = (overrides || []).find(o => o.candidateId === candidateId);
    if(!unstagedOverride) return;

    // all overrides except the one in question
    const overridesBase = (stagedOverrides || []).filter(o => o.candidateId !== unstagedOverride.candidateId);
    setStagedOverrides([].concat(overridesBase, [ unstagedOverride ]))
  }

  // called when an edit status changes
  const onEditingChange = (cdId, isEditing) => {
    const update = {};
    update[cdId] = isEditing;
    setEditingStatuses({
      ...editingStatuses,
      ...update
    })
  }
  // helper function to tell is a certain candidate has an active edit
  const isEditingForCandidate = (cdId) => editingStatuses[cdId];


  return (
    <div style={{}}>
      <div style={{ marginBottom: '12px', display: 'flex', alignItems: 'flex-start' }}>
        <div style={{ maxWidth: '280px' }}>
          <FormField
            style={{ maxWidth: '200px' }}
            alternateComponent={<TextField
              select
              value={readMoreTo}
              size='small'
              onChange={(e) => setReadMoreTo(e?.target?.value)}
            >
              {readMoreOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>}
            label={'Learn more links to'}
          />
          <Typography variant='subtitle1' style={{ marginTop: '4px', fontSize: '11px', opacity: 0.8 }}>
            Determines where voters are taken when they click 'Learn more' about a candidate
          </Typography>
        </div>
        <FormField
          style={{ marginLeft: '32px'}}
          alternateComponent={<Switch
            onChange={(e) => {
              const newVal = e?.target?.checked
              setScoresEnabled(newVal)
              if(newVal) setBadgesEnabled(false)
            }}
            checked={scoresEnabled}
          />}
          label={'Scores enabled'}
        />
        {
          showSegmentOption &&
          <FormField
            style={{ marginLeft: '32px'}}
            alternateComponent={<Switch
              onChange={(e) => setSegmentCandidates(e?.target?.checked)}
              checked={segmentCandidates}
            />}
            label={'Segment candidates'}
          />
        }
        {
          showBadgesOption &&
          <FormField
            style={{ marginLeft: '32px'}}
            alternateComponent={<Switch
              onChange={(e) => {
                const newVal = e?.target?.checked;
                setBadgesEnabled(newVal)
                if(newVal) setScoresEnabled(false)
              }}
              checked={badgesEnabled}
            />}
            label={'Badges enabled'}
          />
        }
      </div>
      <div style={{ marginBottom: '12px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent:'space-between', maxWidth: '650px' }}>
      {
        adding
        ? <>
          <CandidateSearch
            style={{ flex: 1 }}
            onSelect={(e) => {
              addCandidate(e?.target?.value?.candidate)
              setAdding(false)
            }}
            existingCandidates={candidates}
            election={guide?.election?.key || guide?.election}
          />
          <IconButton onClick={() => setAdding(false)}>
            <CancelIcon />
          </IconButton>
        </>
        : <Button
            variant='text'
            color='secondary'
            startIcon={<AddIcon />}
            onClick={() => setAdding(true)}
          >
            Add new candidate
          </Button>
      }
      </div>
      <SearchableList
        itemNameSingular='Candidate'
        itemNamePlural='Candidates'
        style={{ marginTop: '12px', maxHeight: '600px' }}
        onSearch={setSearchText}
      >
        {(filteredCandidates || []).map((cd) => (
          <CandidateEditCell
            key={cd._id}
            candidate={cd}
            scoresEnabled={scoresEnabled}
            badgesEnabled={badgesEnabled}
            customLinkEnabled={readMoreTo === 'custom'}
            override={(overrides || []).find(o => o.candidateId === cd._id)}
            onChange={overrideChanged}
            onRemoveCandidate={removeCandidate}
            editing={isEditingForCandidate(cd._id)}
            onStopEditing={() => {
              resetOverrideForCandidate(cd._id);
              onEditingChange(cd._id, false)
            }}
            onStartEditing={() => onEditingChange(cd._id, true)}
            onSave={() => {
              stageOverrideForCandidate(cd._id)
              onEditingChange(cd._id, false)
            }}
          />
        ))}
      </SearchableList>
      <FormSaveButtons
        style={{ marginTop: '36px' }}
        onCancel={onCancel}
        onSave={saveGuideStep}
      />
    </div>
  )
}
