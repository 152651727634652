import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { LinkShare } from '../DataDisplay';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import { useFeathers } from '@branchpolitics/react.util.feathers';
import { isOrgPermitted } from '../../util';
import { setGuide } from '../../state/actions';

const privateModePossible = true;

const ShareGuide = ({
  style,
  className
}) => {
  const guide = useSelector(state => state.guide);
  const user = useSelector(state => state.user?.data);
  const feathers = useFeathers();
  const dispatch = useDispatch();
  const [ loginRequired, setLoginRequired ] = useState(guide?.privateMode);
  const [ loading, setLoading ] = useState(false);
  const privateModePerms = isOrgPermitted(user, ['eb-admin','eb-editor'], guide?.organization._id || guide?.organization)

  useEffect(() => {
    if(guide && loginRequired !== guide?.privateMode) updatePrivacyStatus(loginRequired)
  }, [ loginRequired ])

  const updatePrivacyStatus = async (setAsPrivate) => {
    if(loading) return;
    setLoading(true)

    try {
      const result = await feathers.getService('endorsement-guides').patch(guide._id, {
        privateMode: setAsPrivate
      })
      console.log(result)
      setLoginRequired(result?.privateMode)
      dispatch(setGuide(result))
    } catch(err) {
      console.log('Failed to change status')
      console.log(err)
    } finally {
      setLoading(false)
    }
  }

  if(!guide) return null;

  let text;
  if(guide.status === 'draft') text = `While you edit, you can share a watermarked version of your landing page with your team.`
  else if(guide.status === 'pending') text = `Before your guide goes live, you can share a watermarked version of your landing page with your team.`
  else if(guide.status === 'live') text = `Anyone can access your landing page at the link below. You can share this link however you'd like, including email, texts, websites, and social media.`
  else if(guide.status === 'inactive') text = `Your guide is inactive, but you can stil share a watermarked version of your landing page with your team.`

  return (
    <Wrapper style={style} className={className}>
      <Typography variant='body1' color='textPrimary' style={{ marginBottom: '12px', marginTop: '4px'}}>
        {text}
      </Typography>
      {
        guide.status !== 'live' && privateModePerms && privateModePossible &&
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop:'4px' }}>
          <Typography variant='body1'><b>Require login?</b></Typography>
          <Switch
            checked={loginRequired}
            disabled={loading}
            onChange={e => setLoginRequired(e?.target?.checked)}
          />
        </div>
      }
      <LinkShare>{guide?.link}</LinkShare>
      {
        guide.status !== 'live' && privateModePossible &&
        <Typography variant='body2' style={{ marginTop: '8px' }}>
          {guide.privateMode
          ? `Only members of your organization will be able to view the page, after logging in.`
          : `Anyone on the internet with the link can view the page, without logging in.`}
        </Typography>
      }
    </Wrapper>
  )
}

const Wrapper = styled.div`

`

export { ShareGuide };
