import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useFormInputs } from '../../util';
import { useFeathers } from '@branchpolitics/react.util.feathers';
import { setOrganization, setUser } from '../../state/actions';
import { useSelector, useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import Checkbox from '@mui/material/Checkbox';

const CreateOrg = ({

}) => {
  // const from = (useLocation() || {}).state?.from;
  const redirectTo = '/dashboard';
  const loggedIn = useSelector(state => state?.user?.loggedIn)
  const organization = useSelector(state => state?.organization);
  const feathers = useFeathers();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [ loading, setLoading ] = useState(false);
  const [ error, setError ] = useState(null);
  const [ formInputs, onChange ] = useFormInputs({
    firstName: '',
    lastName: '',
    orgName: '',
    status: '',
    key: '',
    acceptedTOU: false,
  })

  const submittable = formInputs?.status && formInputs?.orgName && formInputs?.key && formInputs?.acceptedTOU;

  const create = async () => {
    if(!submittable || loading) return;

    setLoading(true)
    setError(null);
    try {
      const org = await feathers.getService('organizations').create({
        name: formInputs?.orgName,
        status: formInputs?.status,
        key: formInputs?.key
      })

      const { user, ...orgRest } = org;
      if(user) dispatch(setUser(user))
      dispatch(setOrganization(orgRest))
    } catch(err) {
      setError(err)
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if(organization) navigate(redirectTo)
  }, [ organization ])

  let formattedError;
  if(error) {
    formattedError = error?.message;
  }

  return (
    <Wrapper>
      <Typography variant='h1'>One last step</Typography>
      <Typography variant='body1' style={{ marginTop: '8px', marginBottom: '24px' }}>Tell us about your organization.</Typography>
      {
        error &&
        <Alert severity="warning" style={{ marginBottom: '24px' }}>
          <Typography variant='body1'>
            {formattedError}
          </Typography>
        </Alert>
      }
      <Typography variant='body1' color='textPrimary'>
        Organization Name
      </Typography>
      <TextField
        variant='outlined'
        name='orgName'
        value={formInputs?.orgName}
        onChange={onChange}
        style={{ marginBottom: '12px' }}
      />
      <Typography variant='body1' color='textPrimary'>
        Organization type
      </Typography>
      <TextField
        select
        placeholder='Organization type'
        name='status'
        value={formInputs?.status}
        onChange={onChange}
        style={{ marginBottom: '20px' }}
      >
        {['501(c)(4)', 'PAC', '501(c)(3)', 'Other'].map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </TextField>
      <Typography variant='body1' color='textPrimary'>
        Abbreviation for your organization
      </Typography>
      <Typography variant='body2' color='textPrimary'>
        This will decide the url of your endorsement builder site.
      </Typography>
      <TextField
        variant='outlined'
        helperText={formInputs?.key ? `ex: ${formInputs?.key}.endorse.guide` : ``}
        name='key'
        value={formInputs?.key}
        onChange={onChange}
        style={{ marginBottom: '12px' }}
      />
      <div style={{ marginBottom: '12px', display: 'flex', marginTop: '20px'}}>
        <Checkbox
          color='secondary'
          size='small'
          checked={formInputs?.acceptedTOU}
          onChange={(e) => {
            const value = e.target.checked;
            onChange({ target: { name: 'acceptedTOU', value }})
          }}
        />
        <Typography variant='body1' color='textPrimary' style={{ marginLeft: '0px'}} >
          Check this box if you have read and agree to comply with our <a href='/terms-of-use' target='_blank'>Terms of Use</a>.
        </Typography>
      </div>
      <Button
        color='primary'
        variant='contained'
        fullWidth
        style={{ marginTop: '24px'}}
        disabled={!submittable || loading}
        size='large'
        onClick={create}
      >
        Continue
      </Button>
    </Wrapper>
  )

}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 48px;
  max-height: 100%;
  overflow-y: scroll;
`

export default CreateOrg;
