import React, { useState, useEffect } from 'react';
import { useFeathers } from '@branchpolitics/react.util.feathers';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setGuide } from '../../../state/actions';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { TwoPane } from '@branchpolitics/react.layout.two-pane';
import { Step } from '@branchpolitics/react.menu.step';
import { MobileFrame } from '@branchpolitics/react.display.mobile-frame';
import { EndorsementGuide, propsFromServerObjects } from '@branchpolitics/react.pages.endorsement-guide';
import { Step1, step1Complete } from './Step1';
import { Step2, step2Complete } from './Step2';
import { Step3, step3Complete } from './Step3';
import { Step4, step4Complete } from './Step4';
import { LoadingPage } from '../../../components';

const Steps = ({

}) => {
  const feathers = useFeathers();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const guide = useSelector(state => state.guide);
  const organization = useSelector(state => state.organization);

  /* Guard loading page if the user doesn't have a guide yet */
  useEffect(() => {
    if(!guide) navigate('/guide/start')
  }, [ guide ])


  /* functions for managing the displaying of the guide preview */
  const [ guidePreview, setGuidePreview ] = useState({});
  const updateGuide = (someFields) => setGuidePreview({
    ...guidePreview,
    ...someFields
  })



  useEffect(() => {
    const values = propsFromServerObjects(guide, organization)
    setGuidePreview({ ...values, isPreview: false })
  }, [])

  /* functions for managing the step navigation */
  const [ activeStep, setActiveStep ] = useState(null);
  const steps = [
    { component: (<Step1 /> ), complete: step1Complete(guide), name: `Describe your guide`, description: `Let voters know what your guide is about.` },
    { component: (<Step2/>), complete: step2Complete(guide), name: `Customize your brand`, description: `Upload logos, images, and choose colors that fit your brand.` },
    { component: (<Step3/>), complete: step3Complete(guide), name: `Choose your candidates`, description: `Assemble your list of endorsed candidates.` },
    { component: (<Step4/>), complete: step4Complete(guide), name: `Customize candidate cards`, description: `Help voters understand your endorsements by adding descriptions or scores to each candidate.` },
  ]

  /* functions for managing saving and updating guide */
  const [ saving, setSaving ] = useState(false);
  const [ error, setError ] = useState(null);
  const onSave = async (newInputs) => {
    if(saving || !feathers) return;
    setSaving(true)
    setError(null)

    try {
      const updateRes = await feathers.getService('endorsement-guides').patch(guide?._id, newInputs)

      dispatch(setGuide(updateRes))
      setActiveStep(null);
      setGuidePreview({ ...propsFromServerObjects(updateRes, organization), isPreview: false })
    } catch(err) {
      setError(err)
      console.error('New error!', err)
    } finally {
      setSaving(false)
    }
  }

  /* functions for managing publishing the guide */
  const guideComplete = step1Complete(guide) && step2Complete(guide) && step3Complete(guide) && step4Complete(guide);

  return (
    <Wrapper>
      <ButtonWrapper>
      {
        guide?.status !== 'draft'
        ?
          <Link to='/dashboard'>
            <Typography variant='body1' style={{ cursor: 'pointer' }}>
              <u>Back to Home</u>
            </Typography>
          </Link>
        :
        <>
          <Link {...(guideComplete ? {to: '/guide/launch'} : { to: '#'})}>
            <Button
              variant='contained'
              style={{ marginRight: '20px'}}
              color='primary'
              size='large'
              disabled={!guideComplete}
            >
              Continue to launch
            </Button>
          </Link>
          <Link to='/dashboard'>
            <Typography variant='body1' style={{ cursor: 'pointer' }}>
              <u>Save for later</u>
            </Typography>
          </Link>
        </>
      }
      </ButtonWrapper>
      <TwoPane>
        <Left style={{ width: '70%' }}>
        {
          typeof(activeStep) === 'number'
          ? <StepWrapper>
              <Step
                number={activeStep + 1}
                complete={steps[activeStep].complete}
                style={{ maxWidth: '500px' }}
                typographyStyle={{ fontWeight: 'bold', fontSize: '20px' }}
                buttonHidden={true}
              >
                {steps[activeStep].name}
              </Step>
              <Typography
                variant='body1'
                color='textPrimary'
                style={{ marginLeft: 'calc(28px + 32px)', marginBottom: '20px' }}
              >
                {steps[activeStep].description}
              </Typography>
              <div style={{
                marginTop: '8px',
                marginLeft: 'calc(28px + 32px)',
                width: 'calc(100% - 28px - 32px - 24px)',
                marginRight: '24px',
                marginBottom: '48px',
                position: 'relative'
              }}>
              {React.cloneElement(
                steps[activeStep].component,
                {
                  onUpdateGuide: updateGuide,
                  onCancel: () => setActiveStep(null),
                  onSave,
                  guide,
                  organization
                }
              )}
              {
                saving &&
                <LoadingPage style={{ opacity: 0.8, position: 'absolute', top: 0, height: '103%', width: '103%' }} />
              }
              </div>
            </StepWrapper>
          : steps.map((step, i) => (
            <StepWrapper key={`step-${i}`}>
              <Step
                number={i + 1}
                complete={step.complete}
                style={{ maxWidth: '500px' }}
                typographyStyle={{ fontSize: '20px', fontWeight: 'bold'}}
                onClick={() => setActiveStep(i)}
              >
                {step.name}
              </Step>
              <Typography
                variant='body1'
                color='textPrimary'
                style={{ marginLeft: 'calc(28px + 32px)', marginBottom: '20px', maxWidth: '400px' }}
              >
                {step.description}
              </Typography>
            </StepWrapper>
          ))
        }
        </Left>
        <Right style={{ width: '30%', paddingTop: '12px' }}>
          <MobileFrame scaleBy={0.7} height={600}>
            <EndorsementGuide {...guidePreview}/>
          </MobileFrame>
        </Right>
      </TwoPane>
    </Wrapper>
  )
}


const Wrapper = styled.div`
  height: 100%;
  max-height: 100%;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute; /* positions against the white background wrapper */
  top: 24px;
  right: 60px;
`

const Right = styled.div`
  display: flex;
  flex-direction: column;
`
const Left = styled.div`
  padding-right: 48px;
  max-height: 100%;
  overflow-y: scroll;
`

const StepWrapper = styled.div`
  width: 100%;
  margin-bottom: 30px;
`

export { Steps };
