import React, { useEffect } from 'react';
import { useFormInputs } from '../../../util';
import { useFeathers } from '@branchpolitics/react.util.feathers';
import {
  FormSaveButtons,
  FormField
} from '../../../components';
import styled from 'styled-components';
import { ColorSelect } from '@branchpolitics/react.input.color-select';
import { PhotoUpload, onImageUploadWithFeathers } from '@branchpolitics/react.input.photo-upload';
import config from '../../../config';

/*
  Takes in the current guide object. Returns true if the step is complete, based on the guide
*/
export const step2Complete = (guide) => guide?.branding?.colorText;

export const Step2 = ({
  onUpdateGuide,
  onSave,
  onCancel,
  guide
}) => {
  const feathers = useFeathers();

  /* helper function for feathers using the upload service */
  const onUploadImage = onImageUploadWithFeathers(feathers, config.serverEndpoint, 'orgBranding');

  /*
    Set initial inputs from the guide on the server.
    The component is re-initialized each time a step is saved/cancelled
  */
  const [ formInputs, onChange ] = useFormInputs({
    logo: guide?.branding?.logo,
    backgroundImage: guide?.branding?.description,
    colorPrimary: guide?.branding?.colorPrimary,
    colorText: guide?.branding?.colorText || '#FFFFFF'
  })

  /**
   * Manages displaying the changes on the guide, in real-time
   * Maps the formInputs of the local component to properties of the EndorsementGuide component
   */
  useEffect(() => {
    onUpdateGuide(formInputs)
  }, [ formInputs ])

  /**
   * Called whenever there are updates to save.
   * Maps the formInputs to the formatted data structure to then be saved on the server
   */
  const saveGuideStep = () => {
    const {
      logo,
      backgroundImage,
      colorText,
      colorPrimary,
    } = formInputs;

    const formInputsMapped = {
      branding: {
        ...( logo ? { logo } : {}),
        ...( backgroundImage ? { backgroundImage } : {}),
        ...( colorText ? { colorText } : {}),
        ...( colorPrimary ? { colorPrimary } : {}),
      }
    }
    onSave(formInputsMapped)
  }


  return (
    <div>
      <FormFieldStyled
        label='Logo (optional)'
        alternateComponent={
          <PhotoUpload
            cropEnabled={false}
            value={formInputs?.logo}
            onUpload={onUploadImage}
            onChange={(i) => onChange({ target: { name: 'logo', value: i }})}
            style={{ height: '100px' }}
          />
        }
      />
      <FormFieldStyled
        label='Background image (optional)'
        alternateComponent={
          <PhotoUpload
            cropEnabled={false}
            value={formInputs?.backgroundImage}
            onUpload={onUploadImage}
            onChange={(i) => onChange({ target: { name: 'backgroundImage', value: i }})}
            style={{ height: '100px' }}
          />
        }
      />
      <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
        <FormFieldStyled
          label='Brand color'
          alternateComponent={
            <div style={{ paddingTop: '8px'}}>
              <ColorSelect
                variant='free'
                value={formInputs?.colorPrimary}
                onChange={(i) => onChange({ target: { name: 'colorPrimary', value: i }})}
              />
            </div>
          }
        />
        <FormFieldStyled
          label='Text color'
          alternateComponent={
            <div style={{ paddingTop: '12px'}}>
              <ColorSelect
                variant='multi'
                options={[ '#000000', '#FFFFFF']}
                value={formInputs?.colorText}
                onChange={(i) => onChange({ target: { name: 'colorText', value: i }})}
              />
            </div>
          }
        />
      </div>
      <FormSaveButtons
        style={{ marginTop: '36px' }}
        onCancel={onCancel}
        onSave={saveGuideStep}
      />
    </div>
  )
}

const FormFieldStyled = styled(FormField)`
  margin-bottom: 24px;
  max-width: 500px;
`
