let config;
if(window.location.hostname.includes('localhost')) {
  config = {
    serverEndpoint: 'http://localhost:3030',
    stripePaymentLink: 'https://buy.stripe.com/test_28o9AW6dDgIVgjC7ss'
  }
} else {
  config = {
    serverEndpoint: 'https://www.branch.vote',
    stripePaymentLink: 'https://buy.stripe.com/6oE3g91jA8da3babII'
  }
}


export default config;
