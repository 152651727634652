import { useState } from 'react';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import OutlinedInput from '@mui/material/OutlinedInput';

export const PasswordField = ({
  value,
  name,
  onChange,
  style
}) => {
  const [ passwordVisible, setPasswordVisible ] = useState(false)
  return (
    <OutlinedInput
      variant='outlined'
      id="outlined-adornment-password"
      type={passwordVisible ? 'text' : 'password'}
      value={value}
      style={style}
      name={name}
      onChange={onChange}
      startAdornment={
        <InputAdornment position="start">
          <LockOutlinedIcon />
        </InputAdornment>
      }
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={() => setPasswordVisible(!passwordVisible)}
            edge="end"
          >
            {passwordVisible ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </InputAdornment>
      }
      placeholder='Password'
    />
  )
}
