import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/CancelOutlined';
import AddIcon from '@mui/icons-material/AddOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import styled from 'styled-components';
import { useFormInputs } from 'util';
import { CandidateListItem } from '../../DataDisplay';
import { FormField } from '../FormField';
import CheckIcon from '@mui/icons-material/CheckCircle';
import { InlineEdit } from '@branchpolitics/react.input.inline-edit';

import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';

import Checkbox from '@mui/material/Checkbox';

export const CandidateEditCell = ({
  candidate,
  override,
  editing,
  scoresEnabled,
  badgesEnabled,
  customLinkEnabled,
  onStopEditing,
  onStartEditing,
  onSave,
  onRemoveCandidate,
  onChange,
}) => {
  const [ scoreEditing, setScoreEditing ] = useState(false);
  const [ linkEditing, setLinkEditing ] = useState(false);
  const onChangeInternal = (e) => {
    const { name, value } = e?.target;

    const valueAdj = name === 'score' ? value.toUpperCase() : value;

    // check if we already have some sort of override for the candidate
    const overrideBase = override || ({
      candidateId: candidate._id,
    })
    const update = {};
    update[name] = valueAdj;

    const overrideNew = {
      ...overrideBase,
      ...update
    }

    onChange(overrideNew)
  }

  return (
    <ListItem active={editing ? 1 : 0} >
      <ListItemTop>
        <CandidateListItem
          style={{ width: 'auto', flex: 1, marginRight: '8px' }}
          candidate={candidate}
        />
        { customLinkEnabled &&
          <div style={{ marginRight: '16px', minHeight: '1px', width: '80px' }}>
            <FormField
              label='Link'
              alternateComponent={<InlineEdit
                value={
                  linkEditing
                  ? (override?.link ?? '')
                  : (override?.link
                    ? <div style={{ display: 'flex' }}>
                      <CheckIcon style={{ marginRight: '4px', height: '17px', width: 'auto'}} color='success'/>
                      <Typography variant='body2'>
                        Added
                      </Typography>
                    </div>
                    : 'None'
                  )
                }
                name='link'
                onBeginEdit={() => setLinkEditing(true)}
                onFinishEdit={() => setLinkEditing(false)}
                onChange={onChangeInternal}
                onSave={onSave}
              />}
            />
          </div>
        }
        {
          badgesEnabled &&
          <div style={{ marginRight: '16px', minHeight: '1px', width: '60px' }}>
            <FormField
              label='Starred'
              alternateComponent={<Checkbox
                checked={typeof(override?.starred) === 'undefined' ? false : override?.starred}
                onChange={event => {
                  const value = event.target.checked;
                  onChangeInternal({ target: { name: 'starred', value }})
                }}
                size='small'
                style={{ height: '36px', width: '36px'}}
                icon={<StarBorderIcon />}
                checkedIcon={<StarIcon />}
              />}
            />
          </div>
        }
        { scoresEnabled &&
          <div style={{ marginRight: '16px', minHeight: '1px', width: '60px' }}>
            <FormField
              label='Score'
              alternateComponent={<InlineEdit
                value={
                  (override?.score && override?.score !== 'None' && override?.score !== 'NONE')
                  ? override?.score
                  : (scoreEditing ? '' : 'None')
                }
                name='score'
                onBeginEdit={() => setScoreEditing(true)}
                onFinishEdit={() => setScoreEditing(false)}
                onChange={onChangeInternal}
                onSave={onSave}
              />}
            />
          </div>
        }
        <IconButton onClick={() => onRemoveCandidate(candidate)} size='small'>
          <DeleteIcon/>
        </IconButton>
      </ListItemTop>
      {
        editing
        ? <>
            <div style={{ margin: '12px' }}>
              <TextFieldStyled
                variant='filled'
                size='small'
                type='text'
                value={override?.explanation || ''}
                name={'explanation'}
                onChange={onChangeInternal}
                placeholder={'Add a short blurb about why you are endorsing the candidate.'}
                rows={3}
                multiline={true}
              />
            </div>
            <ButtonWrapper>
              <Typography variant='body1' style={{ cursor: 'pointer' }} onClick={onStopEditing}>
                <u>Cancel</u>
              </Typography>
              <Button variant='outlined' style={{ marginLeft: '16px'}} color='secondary' size='small' onClick={onSave}>
                Done
              </Button>
            </ButtonWrapper>
          </>
        : <div style={{ paddingLeft:'calc(48px + 8px)', display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: override?.explanation ? '6px' : '0' }}>
          {
            override?.explanation
            // if we already have some description for the candidate
            ? <>
              <CheckIcon style={{ marginRight: '4px', height: '17px', width: 'auto'}} color='success'/>
              <Typography variant='body2'>
                Description added
              </Typography>
              <Typography variant='body2' style={{ cursor: 'pointer', marginLeft: '12px'  }} onClick={onStartEditing}>
                <u>edit</u>
              </Typography>
            </>
            : <Button
              variant='text'
              color='secondary'
              size='small'
              startIcon={<AddIcon size='small'/>}
              onClick={onStartEditing}
            >
              <Typography variant='body2' style={{ letterSpacing: '0.5px'}}>Add description</Typography>
            </Button>
          }
        </div>
      }
    </ListItem>
  )
}


const ListItem = styled.div`
  padding: 8px 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  ${props => props.active ?
    `
      background-color: #FFFFFF;
      border-radius: 8px;
      box-shadow: 2px 2px 8px rgba(0,0,0,0.08);
      border: solid 1px #EEEEEE;
    `
  : `
    &:hover {
      background-color: #F2F2F2;
    }
  `}
`

const ListItemTop = styled.div`
  display: flex;
  align-items: center;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: calc(100% - 12px * 2);
  margin: 0 12px 8px;
`
const TextFieldStyled = styled(TextField)`
  background-color: #EEEEEE;
  width: 100%;

  & > .MuiFilledInput-root {
    padding: 5px;
  }
`
