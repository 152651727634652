import { useState, useEffect } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isPermitted, isOrgPermitted } from './util';
import { setUser, setOrganization, setGuide, logout } from './state/actions';
import styled from 'styled-components';
import { useFeathers } from '@branchpolitics/react.util.feathers';

import { theme, muiTheme } from './theme';
import { ThemeProvider } from 'styled-components';
import { ThemeProvider as MUIThemeProvider } from '@mui/material/styles';
import './App.css';

import {
  ScreenLayout,
  InnerLayoutPrimary,
  LoadingPage
} from './components';

/* Pages */
import Home from './pages/Home';
import Logout from './pages/Logout';
import SignUp from './pages/SignUp';
import SignIn from './pages/SignIn';
import TermsOfUse from './pages/TermsOfUse';
import CreateOrg from './pages/CreateOrg';
import Dashboard from './pages/Dashboard';
import UserManagement from './pages/UserManagement';
import InviteLink from './pages/InviteLink';
import {
  Intro,
  Steps,
  Launch,
  PaymentComplete
} from './pages/LandingPage'


function App() {
  const feathers = useFeathers();
  const dispatch = useDispatch();
  const loggedIn = useSelector(state => state?.user?.loggedIn);
  const [ loaded, setLoaded ] = useState(false);

  const authenticate = async () => {
    if(loggedIn) {
      try {
        const { user } = await feathers.reAuthenticate();
        const org = (user?.organizations || []).find(o => (o.permissions || []).some(p => ['eb-admin','eb-editor','eb-viewer'].includes(p)));
        if(org) {
          const organizationsRes = await feathers.getService('organizations').find({ query: { _id: org.organization }});

          if(organizationsRes.data?.length > 0) {
            const orgDetailRes = await feathers.getService('organizations').get(organizationsRes.data[0].key, { query: { $endorsementGuide: true }});
            dispatch(setOrganization(orgDetailRes))
            dispatch(setGuide(orgDetailRes?.endorsementGuide))
          }
        }
        dispatch(setUser(user))
      } catch(err) {
        dispatch(logout())
      }
    }

    setLoaded(true)
  }

  useEffect(() => {
    if(feathers) authenticate()
  }, [ feathers ])


  return (
    <Wrapper>
    <MUIThemeProvider theme={muiTheme}>
      <BrowserRouter>
        <Routes>
          <Route index element={<Home />}/>
          <Route path='/logout' element={<Logout />}/>
          {/* Elements with skinny center white tile layout  */ }
          <Route element={
            <RequireLoading loaded={loaded}>
              <ScreenLayout innerWrapperStyle={{ maxWidth: '500px', margin: '0 auto 0', height: '100%', justifySelf: 'start' }}/>
            </RequireLoading>
          }>
            <Route
              path="/sign-up"
              element={
                <SignUp />
              }
            />
            <Route
              path="/create-org"
              element={
                <CreateOrg />
              }
            />
            <Route
              path="/invite-link"
              element={
                <InviteLink />
              }
            />
            <Route
              path="/login"
              element={
                <SignIn />
              }
            />
          </Route>
          <Route path='/terms-of-use' element={<TermsOfUse />}/>
          {/* Elements with our full-screen center white tile layout  */ }
          <Route element={
            <RequireLoading loaded={loaded}>
              <ScreenLayout />
            </RequireLoading>
          }>
            <Route
              path="/dashboard"
              element={
                <RequireAuth permissions={['eb-viewer','eb-admin','eb-editor']}>
                  <Dashboard />
                </RequireAuth>
              }
            />
          </Route>
          <Route path="/user-management" element={
            <RequireLoading loaded={loaded}>
              <RequireAuth permissions={['eb-admin']}>
                <ScreenLayout>
                  <InnerLayoutPrimary>
                    <UserManagement />
                  </InnerLayoutPrimary>
                </ScreenLayout>
              </RequireAuth>
            </RequireLoading>
          }/>
          <Route path="guide" element={
            <ScreenLayout>
              <InnerLayoutPrimary/>
            </ScreenLayout>
          }>
            <Route
              path="start"
              element={
                <RequireAuth permissions={['eb-admin','eb-editor']}>
                  <Intro />
                </RequireAuth>
              }
            />
            <Route
              path="home"
              element={
                <RequireAuth permissions={['eb-admin','eb-editor']}>
                  <Steps />
                </RequireAuth>
              }
            />
            <Route
              path="launch"
              element={
                <RequireAuth permissions={['eb-admin','eb-editor']}>
                  <Launch />
                </RequireAuth>
              }
            />
            <Route
              path="payment-complete"
              element={
                <RequireAuth permissions={['eb-admin','eb-editor']}>
                  <PaymentComplete />
                </RequireAuth>
              }
            />
          </Route>
        </Routes>
      </BrowserRouter>
      </MUIThemeProvider>
    </Wrapper>
  );
}


// <>
//   <Route
//     path='/:a'
//     element={<LoadingPage />}
//   />
//   <Route
//     path='/:a/:b'
//     element={<LoadingPage />}
//   />
//   <Route
//     path='/:a/:b/:c'
//     element={<LoadingPage/>}
//   />
// </>
function RequireLoading({ children, loaded }) {
  if (!loaded) {
    return <LoadingPage />;
  } else {
    return children;
  }
}

function RequireAuth({ children, permissions = [], ...rest }) {
  let location = useLocation();
  const user = useSelector((state) => state?.user || {});
  const organization = useSelector(state => state?.organization);
  const { loggedIn } = user;
  const hasPermissions = loggedIn && isOrgPermitted(user?.data, permissions, organization._id);

  if (!loggedIn) {
    // If trying to access a page with improper permissions,
    // redirect to dashboard. If trying to access a page without being
    // logged in, redirect to login.
    const redirectTo = loggedIn ? '/dashboard' : '/';
    return <Navigate to="/login" state={{ from: location }} replace />;
  } else if(!organization) {
    return <Navigate to="/create-org" state={{ from: location }} replace />;
  } else if (!hasPermissions && (permissions || []).includes('eb-viewer')) {
    // if the user is not permitted, even for the most basic thing, log them out
    return <Navigate to="/logout" replace />;
  } else if (!hasPermissions) {
    // if the user is not permitted for more complicated things, take them to the dashboard
    return <Navigate to="/dashboard" replace />;
  }

  return children;
}

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
`

export default App;
