import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useFormInputs } from '../../util';
import { useFeathers } from '@branchpolitics/react.util.feathers';
import { setUser, setOrganization, setGuide } from '../../state/actions';
import { useSelector, useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import {
  PasswordField,
  EmailField,
  PunchListItem
} from '../../components';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import qs from 'qs';
import moment from 'moment';

const InviteLink = ({

}) => {

  const redirectTo = '/dashboard';
  const loggedIn = useSelector(state => state?.user?.loggedIn)
  const feathers = useFeathers();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [ loading, setLoading ] = useState(true);
  const [ error, setError ] = useState(null);
  const [ inviteLinkId, setInviteLinkId ] = useState(null);
  const {
    email,
    organization
  } = qs.parse(location.search, { ignoreQueryPrefix: true });
  const [ formInputs, onChange ] = useFormInputs({
    password: ''
  })

  const reqPasswordLength = formInputs?.password?.length >= 6;
  const reqPasswordUppercase = /[A-Z]/g.test(formInputs?.password || '')
  const reqPasswordSpecial = /[-!$%^&*()_+|~=`{}\[\]:";'<>?,.\/\d]/g.test(formInputs?.password || '')
  const submittable =
    reqPasswordLength &&
    reqPasswordUppercase &&
    reqPasswordSpecial &&
    email;

  const create = async () => {
    if(!submittable || loading) return;

    setLoading(true)
    setError(null)
    try {
      // create the user
      const user = await feathers.getService('users').create({
        email,
        password: formInputs?.password,
        inviteLink: inviteLinkId
      })

      // authenticate them
      const authRes = await feathers.authenticate({
        password: formInputs.password,
        email: email,
        strategy: 'local'
      })

      // And add the org / guide
      const org = (authRes?.user?.organizations || []).find(o => (o.permissions || []).some(p => ['eb-admin','eb-editor','eb-viewer'].includes(p)));
      if(org) {
        const organizationsRes = await feathers.getService('organizations').find({ query: { _id: org.organization }});

        if(organizationsRes.data?.length > 0) {
          const orgDetailRes = await feathers.getService('organizations').get(organizationsRes.data[0].key, { query: { $endorsementGuide: true }});
          dispatch(setOrganization(orgDetailRes))
          dispatch(setGuide(orgDetailRes?.endorsementGuide))
        }
      }
      dispatch(setUser(authRes.user))

      // Before redirecting
      navigate('/dashboard')
    } catch(err) {
      setError(err)
    } finally {
      setLoading(false)
    }
  }

  const validateInvite = async (ctx) => {
    setError(null)
    try {
      const inviteLinks = await feathers.getService('invite-links').find({
        query: {
          email,
          organization,
          valid: true,
          $limit: 1,
          createdAt: { $gt: moment().subtract(3, 'd').format()},
          $sort: { $createdAt: -1 }
        }
      })

      if(! (inviteLinks?.totalMatched > 0)) {
        setError({ code: 1 })
      } else {
        setInviteLinkId(inviteLinks.data[0])
      }
    } catch(err) {
      console.log(err)
      setError(err)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if(loggedIn) navigate(redirectTo)
    else validateInvite()
  }, [ loggedIn ])

  if(loading) {
    return (
      <Wrapper>
        <CircularProgress/>
      </Wrapper>
    )
  }

  let formattedError;
  if(error) {
    switch(error.code) {
      case 1:
        formattedError = `This invite link has expired or has already been used. Please ask your administrator for a new one.`;
        break;
      case 500:
        formattedError = 'Our servers are having an issue. Please try again.';
        break;
      default:
        formattedError = 'Log in failed. Please check your inputs and try again.';
        break;
    }
  }

  return (
    <Wrapper>
      <Typography variant='h1' style={{ marginBottom: '36px' }}>Set up your account</Typography>
      {
        error &&
        <Alert severity="error" style={{ marginBottom: '24px' }}>
          <Typography variant='body1'>
            {formattedError}
          </Typography>
        </Alert>
      }
      <Typography variant='body1' style={{ marginBottom: '0px', fontSize: '16px' }}>
        <b>Email</b>
      </Typography>
      <Typography variant='body1' style={{ marginBottom: '24px', fontSize: '16px' }}>
        {email}
      </Typography>
      <PasswordField
        value={formInputs.password}
        name='password'
        onChange={onChange}
        style={{ marginBottom: '16px' }}
      />
      <Typography variant='body1' color='textPrimary' style={{ fontWeight: 'bold' }}>
        Password must:
      </Typography>
      <PunchListItem style={{ marginTop: '2px' }} complete={reqPasswordLength}>
        Be at least six characters long
      </PunchListItem>
      <PunchListItem style={{ marginTop: '2px' }} complete={reqPasswordUppercase}>
        Have at least one uppercase letter
      </PunchListItem>
      <PunchListItem style={{ marginTop: '2px' }} complete={reqPasswordSpecial}>
        Have at least one symbol or number
      </PunchListItem>
      <Button
        color='primary'
        variant='contained'
        fullWidth
        style={{ marginTop: '24px'}}
        disabled={!submittable || loading}
        size='large'
        onClick={create}
      >
        Sign up
      </Button>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 48px;
  height: 100%;
  max-height: 100%;
`

export default InviteLink;
