import styled from 'styled-components';
import LogoSrc from '../../images/eb-logo.png';
import { useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import { ProfileMenu } from '../Menus';
import { Outlet, Link } from 'react-router-dom';
import { CurrentDate } from '@branchpolitics/react.display.current-date';

const ScreenLayout = ({
  outerWrapperStyle,
  innerWrapperStyle,
  children
}) => {
  const loggedIn = useSelector(state => state?.user?.loggedIn);

  return (
    <OuterWrapper style={outerWrapperStyle}>
      <Header>
        <Link to='/dashboard'>
          <Logo src={LogoSrc}/>
        </Link>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <CurrentDate />
          {
            loggedIn && <ProfileMenu style={{ marginLeft: '20px'}}/>
          }
        </div>
      </Header>
      <InnerWrapper style={innerWrapperStyle}>
        {
          children
          ? children
          : <Outlet/>
        }
      </InnerWrapper>
    </OuterWrapper>
  )
}

/* the background */
const OuterWrapper = styled.div`
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);
  grid-template-columns: 1fr;
  background-color: #F7F9FE;
  width: 100%;
  min-height: 100vh;
  height: 100vh;
  max-height: 100vh;
  padding: 24px 36px;
`

const Header = styled.div`
  width: 100%;
  margin-bottom: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

`

const Logo = styled.img`
  height: 60px;
`
const InnerWrapper = styled.div`
  align-self: center;
  width: 100%;
  background-color: #FAFAFA;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  height: 100%;
  max-height: 100%;
  position: relative;
`
export { ScreenLayout };
