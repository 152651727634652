import React, { useState, useEffect } from 'react';
import { useFeathers } from '@branchpolitics/react.util.feathers';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setGuide } from '../../../state/actions';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { ReactComponent as PersonSvg } from './person.svg';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { TwoPane } from '@branchpolitics/react.layout.two-pane';
import Alert from '@mui/material/Alert';

const Intro = ({

}) => {
  const feathers = useFeathers();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const guide = useSelector(state => state.guide);
  const organization = useSelector(state => state.organization);

  /* Guard loading page if the user already has a guide */
  useEffect(() => {
    if(!organization) navigate('/')
  }, [ organization ])

  useEffect(() => {
    if(guide) navigate('/guide/home')
  }, [ guide ])

  /* handle creating the guide */
  const [ saving, setSaving ] = useState(false);
  const [ error, setError ] = useState(null);
  const createGuide = async () => {
    if(saving || !feathers) return;
    setSaving(true)
    setError(null)

    try {
      const guideNew = await feathers.getService('endorsement-guides').create({
        organization: organization?.key
      });

      dispatch(setGuide(guideNew))
    } catch(err) {
      setError(err)
      console.error('New error!', err)
    } finally {
      setSaving(false)
    }
  }

  return (
    <TwoPane>
      <div style={{ display: 'flex', flexDirection: 'column', width: '60%', paddingTop: '24px' }}>
        {
          error &&
          <Alert severity='error' style={{ marginBottom: '48px', maxWidth: '600px' }}>
            There was an error when creating your guide. Please refresh the page and try again.
          </Alert>
        }
        <Typography variant='h1' color='textSecondary' style={{ marginBottom: '16px' }}>
          Welcome to the landing page builder!
        </Typography>
        <Typography variant='body1' color='textPrimary' style={{ marginBottom: '16px' }}>
          We’ll help you get up and running with your very own landing page in less than <b>10 minutes</b>.
        </Typography>
        <Typography variant='body1' color='textPrimary' style={{ marginBottom: '16px' }}>
          Endorsement Builder allows you to create an interactive guide of your endorsed candidates. You'll do this by searching through our candidate database, adding your endorsed candidates, and supplementing with any information to help voters. You'll also be able to customize the look and feel of the guide to match your brand.
        </Typography>
        <Typography variant='body1' color='textPrimary' style={{ marginBottom: '36px' }}>
          Voters will be able to filter the endorsed guide by the candidates on their address
        </Typography>
        <Button
          color='primary'
          size='large'
          variant='contained'
          style={{ width: '250px'}}
          onClick={createGuide}
          disabled={saving}
        >
          Get started
        </Button>
      </div>
      <div style={{ width: '40%', paddingLeft: '36px', display: 'flex', flexDirection: 'column', }}>
        <PersonSvg style={{ width: '100%', height:'auto' }}/>
      </div>
    </TwoPane>
  )
}

export { Intro };
