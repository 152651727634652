import React, { useEffect } from 'react';
import { useFormInputs } from '../../../util';
import {
  FormField,
  FormSaveButtons
} from '../../../components';
import styled from 'styled-components';

/*
  Takes in the current guide object. Returns true if the step is complete, based on the guide
*/
export const step1Complete = (guide) => guide?.title && guide?.description;

export const Step1 = ({
  onUpdateGuide,
  onSave,
  onCancel,
  guide,
  organization
}) => {
  /*
    Set initial inputs from the guide on the server.
    The component is re-initialized each time a step is saved/cancelled
  */
  const [ formInputs, onChange ] = useFormInputs({
    title: guide?.title || '',
    description: guide?.description || '',
    website: (guide?.links || []).find(l => l?.mediaType === 'website')?.url || '',
    paidForOverride: guide?.paidForOverride || organization?.name
  })

  /**
   * Manages displaying the changes on the guide, in real-time
   * Maps the formInputs of the local component to properties of the EndorsementGuide component
   */
  useEffect(() => {
    const formInputsMapped = {
      title: formInputs?.title || 'Title goes here',
      description: formInputs?.description || 'Guide description goes here',
      website: formInputs?.website,
      paidFor: formInputs?.paidForOverride
    }
    onUpdateGuide(formInputsMapped)
  }, [ formInputs ])

  /**
   * Called whenever there are updates to save.
   * Maps the formInputs to the formatted data structure to then be saved on the server
   */
  const saveGuideStep = () => {
    const formInputsMapped = {
      title: formInputs.title || '',
      description: formInputs.description || '',
      links: formInputs?.website ? [ formInputs.website ] : [],
      paidForOverride: formInputs?.paidForOverride
    }
    onSave(formInputsMapped)
  }


  return (
    <div>
      <FormFieldStyled
        label='Title'
        placeholder='What do you want to call your guide?'
        value={formInputs.title}
        name='title'
        onChange={onChange}
      />
      <FormFieldStyled
        label='Guide description'
        placeholder={`Take 2-3 sentences to describe your organization's endorsement process, including the issues you care aobut and the criteria used to pick your endorsements.`}
        value={formInputs.description}
        name='description'
        onChange={onChange}
        longer={true}
      />
      <FormFieldStyled
        label='Your website'
        placeholder='https://'
        value={formInputs.website}
        name='website'
        onChange={onChange}
      />
      <FormFieldStyled
        label='Paid for by'
        placeholder='Your legal name'
        value={formInputs.paidForOverride}
        name='paidForOverride'
        onChange={onChange}
      />
      <FormSaveButtons
        style={{ marginTop: '36px' }}
        onCancel={onCancel}
        onSave={saveGuideStep}
      />
    </div>
  )
}

const FormFieldStyled = styled(FormField)`
  margin-bottom: 16px;
  max-width: 500px;
`
