import './Home.css';
import { Link } from 'react-router-dom';
import EbLogo from '../../images/eb-logo.png'
import styled from 'styled-components';
import { useSelector } from 'react-redux';

const Home = () => {
  const loggedIn = useSelector(state => state?.user?.loggedIn);
  return (
    <div class="wrapper">
  		<div class='header'>
  			<div>
  				<img src={EbLogo} class='logo'/>
          <ActionButtons>
          {
            loggedIn
            ? <Link to='/dashboard'>
              <div class="waitlist-button">
                Dashboard
              </div>
            </Link>
            : <>
              <Link to='/login'>
                <span class="subtitle-span" style={{ fontWeight: 'bold', fontSize: '20px' }}>
                  Sign in
                </span>
              </Link>
      				<Link to='/sign-up'>
      					<div class="waitlist-button">
      						Sign up
      					</div>
      				</Link>
            </>
          }

          </ActionButtons>
  			</div>
  		</div>
  		<div class="section-1-wrapper">
        <span class="title-span">Give your endorsed candidates&#8198;<br class='desktop'/>the platform they need.</span>
        <span class="subtitle-span">Endorsement Builder is your digital toolbox for publishing and promoting&#8198;<br class='desktop'/>your endorsed candidates.</span>
  			<img src="images/people.svg" class='people-img'/>
  			<a href='http://eepurl.com/h0M5y1'>
  				<div class="waitlist-button big" >
  	        Advance sign-up
  	      </div>
  			</a>
  		</div>
  		<div class="section-2-wrapper">
  			<span class="title-2-span">
  				Publish an interactive landing page&#8198;<br class='desktop'/>for your endorsed candidates.
  			</span>
  			<div class="selling-point first">
  				<div class='copy'>
  					<span class="selling-point-title">
  						Personalized for voters
  					</span>
  					<span class="selling-point-text">
  						Only a small percentage of voters know which districts they live in.<br/><br/>Our guides allow voters to <b>filter by address</b>, so they know which candidates are relevant to them and their ballot. This means your endorsements can have a bigger impact on who voters choose.
  					</span>
  				</div>
  				<div class='spacer'></div>
  				<video class='landing-page-img' muted autoplay loop playsinline>
  					<source src='images/landing-page-gif.mp4' type='video/mp4'/>
  				</video>
  				<img class="blob-1" src="images/blob-1.png"/>
  			</div>
  			<div class="selling-point right">
  				<div class='screenshot-group'>
  					<img src='/images/frame-1.png' class="frame-1"/>
  					<img src='/images/frame-2.png' class="frame-2"/>
  					<img src='/images/frame-3.png' class="frame-3"/>
  				</div>
  				<div class='spacer'></div>
  				<div class='copy right'>
  					<span class="selling-point-title">
  						Get up-and-running quick
  					</span>
  					<span class="selling-point-text">
  						Avoid spending hours gathering candidate photos and bios. Endorsement Builder has information on thousands of candidates.
  <br/><br/>
  						Create your guide, brand it, select your candidates, and launch your guide—<b>in just 10 minutes</b>.
  					</span>
  				</div>
  			</div>
  			<div class="selling-point">
  				<div class='copy'>
  					<span class="selling-point-title">
  						Save money
  					</span>
  					<span class="selling-point-text">
  						It costs a lot build and update a custom page on your website. Use Endorsement Builder to launch a beautiful landing page with all of your endorsements for just <b>$199 / month</b>. Add new candidates at any time with a few simple clicks.
  					</span>
  				</div>
  				<div class='spacer'></div>
  				<div class="price-circle">

  					<span class="price">$199</span>
  					<span>per month</span>
  				</div>
  			</div>

  			<div class="end-cap">
  				<span class='end-cap-text'>
  					Get up and running with a trial version in <b>10 minutes.</b> No up-front payment required.
  				</span>
  				<a href='http://eepurl.com/h0M5y1'>
  					<span class="waitlist-button">
  						Advance sign-up
  					</span>
  				</a>
  			</div>
  		</div>

  		<img src='images/blob-2.png' class='blob-2'/>
  		<div class='section-3-wrapper'>
  			<div class="email-container">
  				<span class="v5444_25271"><b>Have questions?</b></span>
  				<span class="v5444_25272">Drop us an email</span>
  				<span class="v5444_25273"><b>hello@branch.chat</b></span>
  			</div>
  		</div>
  	</div>
  );
}

const ActionButtons = styled.div`
  display: flex;
  align-items: center;
  * {
    margin-left: 24px;
  }
`

export default Home;
