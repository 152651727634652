import { createStore, applyMiddleware } from 'redux';
import reducers from './reducers';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { loadState, saveState, loadAuthToken } from './localstorage';
import throttle from 'lodash.throttle';

// Configure the store
let persistedState = loadState();

let middleware = [
  thunkMiddleware, // lets us dispatch() functions
];

if(process.env.NODE_ENV !== 'production') {
  const loggerMiddleware = createLogger();  // neat middleware that logs actions
  middleware.push(loggerMiddleware);
}

const store = createStore(
  reducers,
  persistedState,
  applyMiddleware(
    ...middleware
  )
)

store.subscribe(throttle(() => {
  const persistState = store.getState();
  const { user, organization, guide } = persistState;

  const stateToSave = {
    user,
    organization,
    guide
  };
  saveState(stateToSave);
}, 5000));

export default store;
