import { combineReducers } from 'redux';
import {
  SET_USER,
  SET_ORGANIZATION,
  GET_ELECTIONS,
  SET_GUIDE,
  USER_LOGOUT
} from './actions';

const user = (state = {
  loggedIn: false,
  data: {}
}, action) => {
  switch(action.type) {
    case SET_USER:
      if(action.response) {
        return {
          loggedIn: true,
          data: action.response
        }
      }
    case USER_LOGOUT:
      return {
        data: {},
        loggedIn: false
      };
    default:
      return state;
  }
}

const organization = (state = null, action) => {
  switch(action.type) {
    case SET_ORGANIZATION:
      if(action.response) {
        return action.response;
      }
    case USER_LOGOUT:
      return null;
    default:
      return state;
  }
}

const guide = (state = null, action) => {
  switch(action.type) {
    case SET_GUIDE:
      if(action.response) {
        return action.response;
      }
    case USER_LOGOUT:
      return null;
    default:
      return state;
  }
}


const combinedReducers = combineReducers({
  user,
  organization,
  guide
});
export default combinedReducers;
