import React from 'react';
import { Outlet, Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';

const InnerLayoutPrimary = ({
  children
}) => {
  const { pathname = '' } = useLocation();
  let breadcrumbs = [];
  if(pathname.startsWith('/guide')) {
    breadcrumbs.push({ to: '/dashboard', text: 'Dashboard' })
    if(pathname.startsWith('/guide/start')) breadcrumbs.push({ text: 'Get started' })
    else if(pathname.startsWith('/guide/home')) breadcrumbs.push({ text: 'Landing page editor' })
    else if(pathname.startsWith('/guide/launch')) {
      breadcrumbs.push({ to: '/guide/home', text: 'Landing page editor' })
      breadcrumbs.push({ text: 'Launch' })
    }
  }


  const hasBreadcrumb = breadcrumbs.length > 0;

  return (
    <Wrapper>
      {
        hasBreadcrumb &&
        <Header>
          <Breadcrumbs separator="›">
            {breadcrumbs.map((bc, idx) => {
              if(bc?.to) {
                return (
                  <Link to={bc.to}>
                    <Typography variant='body1' color='textPrimary' style={{ opacity: 0.6 }}>
                      {bc.text}
                    </Typography>
                  </Link>
                );
              } else {
                return (
                  <Typography variant='body1' color='textPrimary' style={{ fontWeight: 'bold' }}>
                    {bc.text}
                  </Typography>
                );
              }
            })}
          </Breadcrumbs>
        </Header>
      }
      <Content>
      {
        children
        ? children
        : <Outlet/>
      }
      </Content>
    </Wrapper>
  )
}


const Wrapper = styled.div`
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);
  grid-template-columns: 1fr;
  align-items: stretch;
  height: 100%;
  max-height: 100%;
`

const Header = styled.div`
  padding: 24px 36px;
`

const Content = styled.div`
  height: 100%;
  max-height: 100%;
  min-height: 100%;
  padding: 24px 64px;
`

export { InnerLayoutPrimary };
