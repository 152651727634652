import styled from 'styled-components';
import Button from '@mui/material/Button';
import { ReactComponent as FacebookSVG } from './facebook.svg';
import { ReactComponent as GoogleSVG } from './google.svg';
import qs from 'qs';
import config from '../../../config';

const oauthBase = `${config.serverEndpoint}/oauth`

const OAuthButton = ({
  provider,
  onSuccess,
  onError,
  onComplete,
  beforeClick,
  style = {},
  text,
  size = 'large',
  extraParams
}) => {
  const oauthQuery = qs.stringify({
    ...(extraParams ? { extraData: extraParams } : {}),
    ...(onSuccess ? { postRedirectSuccess: onSuccess } : {}),
    ...(onError ? { postRedirectError: onError } : {}),
    ...(onComplete ? { postRedirect: onComplete } : {}),
  })

  if(provider === 'google') {
    return (
      <GoogleButton
        onClick={() => {
          if(beforeClick) beforeClick()
          window.location.href = `${oauthBase}/google${oauthQuery?.length > 0 ? '?' : ''}${oauthQuery}`
        }}
        variant='contained'
        size={size}
        style={{ backgroundColor: '#FFFFFF', ...style }}
        disableTouchRipple
      >
        <GoogleSVG/>
        <div>{text ? text.toUpperCase() : ('Continue with Google').toUpperCase()}</div>
      </GoogleButton>
    )
  } else {
    return (
      <FacebookButton
        onClick={() => {
          if(beforeClick) beforeClick()
          window.location.href = `${oauthBase}/facebook${oauthQuery?.length > 0 ? '?' : ''}${oauthQuery}`
        }}
        variant='contained'
        size={size}
        style={{ backgroundColor: '#3975EA', ...style }}
        disableTouchRipple
      >
        <FacebookSVG/>
        <div>{text ? text.toUpperCase() : ('Continue with Facebook').toUpperCase()}</div>
      </FacebookButton>
    )
  }
}


const OAuthButtonStyle = styled(Button)`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  svg {
    height: 20px;
    width: 20px;
    margin-right: 12px;
  }
  div.spacer {
    height: 20px;
    width: 20px;
    margin-left: 8px;
  }
  div {
    margin-top: 2px;
    letter-spacing: 1px;
    font-size: 13.5px;
  }

`
const FacebookButton = styled(OAuthButtonStyle)`
  background-color: #3975EA;


  div {
    color: #FFFFFF;
  }
  svg {
    height: 22px;
    width: 22px;
  }
  div.spacer {
    height: 22px;
    width: 22px;
  }

`
const GoogleButton = styled(OAuthButtonStyle)`
  div {
    color: #000000;
  }
`

export { OAuthButton };
