import { CirclePhoto } from '@branchpolitics/react.display.circle-photo';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';

const CandidateListItem = ({ candidate, alreadyAdded, style, className }) => {
  return (
    <Wrapper style={style} className={className}>
      <CirclePhoto src={candidate?.photoPathFace} size='small'/>
      <div style={{ flex: 1, marginLeft: '8px' }}>
        <div style={{ display: 'flex', alignItems: 'center'}}>
          <Typography variant='body1' style={{ marginTop: '2px', marginBottom: '-2px'}}>
            <b>{candidate?.name}</b>
          </Typography>
          {
            alreadyAdded &&
            <>
              <CheckIcon color='success' style={{ marginLeft: '16px', height: '20px', width: 'auto' }}/>
              <Typography vairant='body2' style={{ color: '#888888', fontSize: '13px', marginLeft: '2px', marginTop: '0px', marginBottom: '-2px' }}>
                <b>{'Added'.toUpperCase()}</b>
              </Typography>
            </>
          }
        </div>
        <Typography variant='body2' style={{ opacity: 0.8, marginBottom: '-4px' }}>
         Running for {candidate?.race?.officeName}, {candidate?.race?.district?.shortName}
        </Typography>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 0;
  width: 100%;
  display: flex;
  align-items: center;
`

export { CandidateListItem };
