import React from 'react';
import * as ReactDOM from 'react-dom/client';
import App from './App';
import store from './state/store';
import { Provider } from 'react-redux';
import { FeathersProvider } from '@branchpolitics/react.util.feathers';
import config from './config';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <FeathersProvider
      authStorageKey='auth-endorsement-b'
      provider='rest'
      serverEndpoint={config.serverEndpoint}
    >
      <Provider store={store}>
        <App />
      </Provider>
    </FeathersProvider>
  // </React.StrictMode>
  // document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
