import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFeathers } from '@branchpolitics/react.util.feathers';
import { Link } from 'react-router-dom';
import { setGuide } from '../../../state/actions';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import styled from 'styled-components';
import { ReactComponent as GreenCheck } from './green-check.svg';

export const PaymentComplete = ({ }) =>{
  const feathers = useFeathers();
  const dispatch = useDispatch();
  const guide = useSelector(state => state?.guide);
  const [ confirmed, setConfirmed ] = useState(false);
  const [ error, setError ] = useState(null);

  useEffect(() => {
    if(feathers) setAsPending()
  }, [ feathers ])

  const setAsPending = async () => {

    try {
      const res = await feathers.getService('endorsement-guides').patch(guide?._id, {
        status: 'pending'
      })
      dispatch(setGuide(res))
    } catch(err) {
      console.log('Error')
      console.log(err)
      setError(err)
    } finally {
      setConfirmed(true)
    }
  }


  if(!confirmed) {
    return (
      <Wrapper>
        <CircularProgress style={{ alignSelf: 'center' }}/>
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      {
        error &&
        <>
          <Typography variant='h1'>
            Uh-oh!
          </Typography>
          <Typography variant='body1' style={{ marginTop: '16px'}}>
            There was an error when updating your guide status. Please inform the Branch team and we'll get it sorted and bring your landing page live.
          </Typography>
        </>
      }
      {
        !error &&
        <>
          <GreenCheck style={{ marginBottom: '24px', alignSelf: 'center' }} />
          <Typography variant='h1'>
            You're all set!
          </Typography>
          <Typography variant='body1' style={{ marginTop: '16px'}}>
            Your guide is in final review by our team. This will be completed and your guide will be pushedw live in 1-2 days. We'll notify you when it goes live.
          </Typography>
        </>
      }
      <Link to='/dashboard' style={{ marginTop: '60px'}}>
        <Button
          size='large'
          color='primary'
          variant='contained'
          fullWidth
          style={{ height: '50px'}}
        >
          Back to home
        </Button>
      </Link>
    </Wrapper>
  )
}

const Wrapper  = styled.div`
  width: 100%;
  max-width: 400px;
  margin: 0 auto 0;
  min-height: 80%;

  display: flex;
  align-items: stretch;
  flex-direction: column;
  justify-content: center;

  h1, p {
    text-align: center;
  }
`
