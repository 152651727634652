import styled from 'styled-components';
import { useTheme } from 'styled-components';

function Checkbox({
  style,
  checked,
  className,
  size='medium'
}) {
  const theme = useTheme();
  return (
    <Wrapper style={style} selected={checked ? 1 : 0}>
      {
        checked &&
        <svg className='checkbox-svg' width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="20" height="20" fill={`${theme.palette.buttonPrimary}`}/>
          <path d="M13.7322 5.74023C13.6002 5.74023 13.5282 5.74023 13.3962 5.74023L8.77617 10.3602L6.61617 8.20023C6.48417 8.20023 6.41217 8.20023 6.28017 8.20023L4.57617 9.89223C4.57617 10.0242 4.57617 10.0962 4.57617 10.2282L8.60817 14.2602C8.74017 14.2602 8.81217 14.2602 8.94417 14.2602L15.4242 7.78023C15.4242 7.64823 15.4242 7.57623 15.4242 7.44423L13.7322 5.74023Z" fill="white"/>
        </svg>
      }
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: 20px;
  width: 20px;
  min-height: 20px;
  min-width: 20px;
  border-radius: 4px;

  cursor: pointer;
  overflow: none;
  background-color: #FFFFFF;

  ${
    props => props.selected
    ? `border: 1px solid #00000000;`
    : `border: 1px solid #00000040;`
  }
  svg {
    height: 20px;
    width: 20px;
    min-height: 20px;
    min-width: 20px;
    border-radius: 4px;
  }
`

export { Checkbox };
